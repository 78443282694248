import {Box, Button, Theme, Typography} from "@mui/material";
import React from "react";
import {makeStyles} from "tss-react/mui";
import {tt} from "../../../core/Localization";
import Icons8AppleLogo from "../../../icons/Icons8AppleLogo";
import {FlavorByEnvironment} from "../../../flavor-config";
import Icons8GooglePlay2 from "../../../icons/Icons8GooglePlay2";

export const useStyles = makeStyles()((theme: Theme) => ({
        button: {
            padding: 8,
            height: 50,
            borderRadius: 8,
            border: `1px solid #939292`,
            backgroundColor: 'black',
            fontSize:
                14,
            color: "white",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            ":hover": {
                backgroundColor: 'black',
            },
            justifyContent: "start",
            minWidth: 160,
        },
        textsContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",
        },
        text: {
            fontWeight: 600,
            color: "white",
            fontSize: 15,
        },
        label: {
            fontSize: 11,
        }
    })
);

export interface DownloadAppButtonProps {
    className?: string;
    variant: 'googlePlay' | 'appStore'
}

export default function DownloadAppButton(props: DownloadAppButtonProps) {
    const {
        className,
        variant,
    } = props;

    const {classes, cx} = useStyles();
    const theClassName = cx(
        className,
        classes.button,
    );

    let icon;
    let text;
    if (variant == 'googlePlay') {
        icon = <Icons8GooglePlay2/>;
        text = 'Google Play';
    } else {
        icon = <Box sx={{'svg': {color: 'white'}}}><Icons8AppleLogo/></Box>;
        text = 'App Store';
    }

    let onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (variant === "googlePlay") {
            window.open(FlavorByEnvironment()!.googlePlayLink, '_blank', 'noreferrer');
        } else {
            window.open(FlavorByEnvironment()!.appstoreLink, '_blank', 'noreferrer');
        }
    };

    return (
        <Button
            disableElevation={true}
            className={theClassName}
            variant={"contained"}
            onClick={onClick}>
            {icon}
            <Box pr={1}/>
            <Box className={classes.textsContainer}>
                <Typography className={classes.label}>
                    {tt('downloadApp.button.label')}
                </Typography>
                <Typography className={classes.text}>
                    {text}
                </Typography>
            </Box>

        </Button>
    );
}