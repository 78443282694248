import React, {useContext, useEffect, useState} from "react";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../form/FormBuilder";
import {tt} from "../../../core/Localization";
import {Box, Divider, Theme, Typography, useTheme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {AppDataContext} from "../../../AppData";
import {AddressFragment, ContractType, EmployeeRole} from "../../../generated/graphql/graphql";
import {ContractTypeString} from "../../../service/EmployeeService";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import AppButton from "../buttons/AppButton";
import {UserAddressSingle} from "../../../service/UserService";
import {GeocodeAddressToCoordinate} from "../../../service/MapsService";
import WorkerEditScreenShimmer from "../shimmers/screenSpecificShimmers/WorkerEditScreenShimmer";
import {Option} from "../form/InputProps";
import {userEmployeeCanWriteEmployeeByRoleCascade} from "../../../service/PrivilegesService";

export const useStyles = makeStyles()((theme: Theme) => ({
    hourlyRateInput: {
        'input': {
            textAlign: "end",
        }
    },
    bold: {
        fontWeight: 700,
    },
    submitRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    button: {
        "@media (max-width: 767px)": {
            flexGrow: 1,
        }
    }
}));

export interface IWorkersFormProps {
    isEdit?: boolean;
    formType?: 'worker' | 'profile' | undefined;
    isMe?: boolean;
    firstName?: string | NullOrUndefined;
    lastName?: string | NullOrUndefined;
    phone?: string | NullOrUndefined;
    email?: string | NullOrUndefined;
    address?: AddressFragment | NullOrUndefined;
    position?: EmployeeRole | NullOrUndefined;
    contractType?: string | NullOrUndefined;
    hourlyWage?: number | NullOrUndefined;
    distanceRate?: number | NullOrUndefined;
    onSubmit: (update: IWorkersFormUpdate) => void;
    submitRight?: boolean;
    loading?: boolean;
    loadingData?: boolean;
    modalIsOpen?: boolean;
    canPermissionsUpdateForRole?: boolean;
}

export interface IWorkersFormUpdate {
    inputs: IInputsData;
    addressFragment?: AddressFragment;
}

export default function WorkersAndProfileForm(props: IWorkersFormProps) {
    const appDataContext = useContext(AppDataContext);
    const {currency, companyEmployeeRole} = appDataContext;
    const theme = useTheme();

    const {
        isEdit,
        formType,
        isMe,
        contractType,
        hourlyWage,
        firstName,
        lastName,
        address,
        position,
        phone,
        email,
        onSubmit,
        submitRight,
        loading,
        modalIsOpen,
        loadingData,
        canPermissionsUpdateForRole,
        distanceRate,
    } = props;

    const theFormType = formType || 'worker';

    const {classes} = useStyles();

    const availableRoles: Option[] = [];
    if (companyEmployeeRole && userEmployeeCanWriteEmployeeByRoleCascade(companyEmployeeRole, EmployeeRole.Admin)) {
        availableRoles.push({label: tt('common.owner'), value: EmployeeRole.Admin});
    }
    if (companyEmployeeRole && userEmployeeCanWriteEmployeeByRoleCascade(companyEmployeeRole, EmployeeRole.Manager)) {
        availableRoles.push({label: tt('common.Manager'), value: EmployeeRole.Manager});
    }
    if (companyEmployeeRole && userEmployeeCanWriteEmployeeByRoleCascade(companyEmployeeRole, EmployeeRole.HeadOfWorkers)) {
        availableRoles.push({label: tt('common.HeadOfWorkers'), value: EmployeeRole.HeadOfWorkers});
    }
    if (companyEmployeeRole && userEmployeeCanWriteEmployeeByRoleCascade(companyEmployeeRole, EmployeeRole.Worker)) {
        availableRoles.push({label: tt('common.worker'), value: EmployeeRole.Worker});
    }

    const [inputs, setInputs] = useState<IInputsData>({
        firstName: {
            testId: 'workerProfileFormFirstNameInput',
            type: InputType.Text,
            label: `${tt('common.firstName')}*`,
            value: firstName || '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        lastName: {
            testId: 'workerProfileFormLastNameInput',
            type: InputType.Text,
            label: `${tt('common.lastName')}*`,
            value: lastName || '',
            required: true,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        phone: {
            testId: 'workerProfileFormPhoneInput',
            type: InputType.ButtonTextField,
            label: tt('common.phone'),
            toggleButtonText: tt('common.addPhone'),
            value: phone || '',
            required: false,
            inputMode: 'tel',
            grid: {
                sm: 6,
                xs: 12
            },
        },
        email: {
            testId: 'workerProfileContactEmailInput',
            type: InputType.ButtonTextField,
            label: tt('common.contactEmail'),
            toggleButtonText: tt('common.addContactEmail'),
            value: email || '',
            required: false,
            requireEmail: true,
            grid: {
                sm: 6,
                xs: 12,
            },
        },
        address: {
            testId: 'workerProfileFormAddressInput',
            type: InputType.GooglePlacesAutocomplete,
            label: tt('common.address'),
            value: address ? UserAddressSingle(address) : '',
            required: false,
            toggleVisible: true,
            toggleVisibleValue: false,
            toggleVisibleText: tt('common.addAddress'),
            placeholder: tt('common.addAddress.placeholder'),
        },
        divider: {
            type: InputType.CustomRenderer,
            render: () => {
                if (theFormType === "profile") {
                    return null;
                }

                return (
                    <Box>
                        <Divider sx={{mb: 2}}/>
                        <Typography
                            sx={{
                                mb: 1,
                                fontSize: 12,
                                fontWeight: 700,
                                color: kAppColors.text.secondaryLighterForDarkMode(theme.palette.mode === "dark"),
                                textTransform: 'uppercase',
                            }}>{tt('common.work')}</Typography>
                    </Box>
                );
            },
            value: '',
            label: '',
        },
        position: {
            testId: 'workerProfileFormPositionInput',
            type: InputType.Select,
            label: `${tt('common.position')}*`,
            options: availableRoles,
            value: position || '',
            required: theFormType !== 'profile',
            helperText: undefined,
            readOnly: isMe,
            grid: {
                sm: 6,
                xs: 12
            },
        },
        contractType: {
            testId: 'workerProfileFormContractTypeInput',
            type: InputType.ButtonSelect,
            label: tt('common.contractType'),
            toggleButtonText: tt('common.addContractType'),
            options: [
                {label: ContractTypeString(ContractType.FullTime), value: ContractType.FullTime},
                {label: ContractTypeString(ContractType.PartTime), value: ContractType.PartTime},
                {label: ContractTypeString(ContractType.Contract), value: ContractType.Contract},
                {label: ContractTypeString(ContractType.Temporary), value: ContractType.Temporary},
                {label: ContractTypeString(ContractType.SelfEmployed), value: ContractType.SelfEmployed},
                {label: ContractTypeString(ContractType.None), value: ContractType.None},
            ],
            value: contractType || '',
            required: false,
            grid: {
                sm: 6,
                xs: 12
            },
            hidden: theFormType === 'profile',
        },
        hourlyWage: {
            testId: 'workerProfileFormHourlyWageInput',
            className: classes.hourlyRateInput,
            type: InputType.ButtonTextField,
            numbersOnly: true,
            label: tt('common.hourlyWage'),
            toggleButtonText: tt('common.addHourlyWage'),
            value: hourlyWage || '',
            required: false,
            inputMode: "decimal",
            helperText: tt('newWorker.popup.servesAsBaseForWages'),
            suffixJSX: () => <Typography
                sx={{
                    mt: 2.5,
                    fontSize: 14,
                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                }}>{currency}</Typography>,
            grid: {
                sm: 6,
                xs: 12
            },
            hidden: theFormType === 'profile',
        },
        distanceRate: {
            testId: 'workerProfileFormDistanceRateInput',
            className: classes.hourlyRateInput,
            type: InputType.ButtonTextField,
            numbersOnly: true,
            label: tt('common.distanceRate'),
            toggleButtonText: tt('common.distanceRate'),
            value: distanceRate || '',
            required: false,
            inputMode: "decimal",
            helperText: tt('newWorker.popup.servesAsBaseForWages'),
            suffixJSX: () => <Typography
                sx={{
                    mt: 2.5,
                    fontSize: 14,
                    color: kAppColors.text.primary(theme.palette.mode === 'dark'),
                }}>{currency}</Typography>,
            grid: {
                sm: 6,
                xs: 12
            },
            hidden: theFormType === 'profile',
        },
        canPermissionsUpdateForRole: {
            type: InputType.CheckBox,
            value: false,
            label: tt('workerEdit.form.applyPermissionsForAll'),
            hidden: true,
        },
    });

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                firstName: {
                    ...prev.firstName,
                    value: firstName || '',
                },
                lastName: {
                    ...prev.lastName,
                    value: lastName || '',
                },
                phone: {
                    ...prev.phone,
                    value: phone || '',
                },
                email: {
                    ...prev.email,
                    value: email || '',
                },
                address: {
                    ...prev.address,
                    value: address ? UserAddressSingle(address) : '',
                    toggleVisibleValue: address ? !!UserAddressSingle(address) : false,
                },
                divider: {
                    ...prev.divider,
                },
                position: {
                    ...prev.position,
                    readOnly: isMe,
                    value: position || '',
                    hidden: theFormType === 'profile',
                },
                contractType: {
                    ...prev.contractType,
                    value: contractType || '',
                    hidden: theFormType === 'profile',
                    grid: {
                        sm: 6,
                        xs: 12
                    },
                },
                hourlyWage: {
                    ...prev.hourlyWage,
                    value: hourlyWage || '',
                    hidden: theFormType === 'profile',
                    grid: {
                        sm: 6,
                        xs: 12
                    },
                },
                distanceRate: {
                    ...prev.distanceRate,
                    value: distanceRate || '',
                    hidden: theFormType === 'profile',
                    grid: {
                        sm: 6,
                        xs: 12
                    },
                },
                canPermissionsUpdateForRole: {
                    ...prev.canPermissionsUpdateForRole,
                    value: (inputs.position.value !== position && canPermissionsUpdateForRole) || false,
                    hidden: !(inputs.position.value !== position && canPermissionsUpdateForRole),
                },
            };
        });
    }, [isMe, firstName, lastName, phone, email, address, position, contractType, hourlyWage, canPermissionsUpdateForRole]);

    useEffect(() => {
        setInputs(prev => {
            return {
                ...prev,
                canPermissionsUpdateForRole: {
                    ...prev.canPermissionsUpdateForRole,
                    value: (inputs.position.value !== position && canPermissionsUpdateForRole) || false,
                    hidden: !(inputs.position.value !== position && canPermissionsUpdateForRole),
                },
            };
        });
    }, [position, inputs.position.value, canPermissionsUpdateForRole]);

    const [service, setService] = useState<any>();

    /**
     * Parse singleLine address to Address fragment.
     */
    const AddressParts = async () => {
        const theService = service || new window.google.maps.Geocoder();
        if (!service) {
            setService(theService);
        }

        if (inputs.address.value) {
            const parsedAddress = (await GeocodeAddressToCoordinate({
                service: theService,
                address: inputs.address.value,
            }))?.addressFragment;

            if (parsedAddress) {
                parsedAddress.id = address?.id;
            }

            return parsedAddress || undefined;
        }

        return undefined;
    };

    const [positionHelperText, setPositionHelperText] = useState<string>();

    useEffect(() => {
        if (theFormType !== "profile") {
            const shouldClear = !(inputs.position.value === EmployeeRole.Worker || inputs.position.value === EmployeeRole.Admin || inputs.position.value === EmployeeRole.HeadOfWorkers || inputs.position.value === EmployeeRole.Manager);

            if (inputs.position.value === EmployeeRole.Worker && positionHelperText !== 'worker') {
                setInputs(prev => {
                    return {
                        ...prev,
                        position: {
                            ...prev.position,
                            helperText: <PositionWorkerHelperTextWorker/>,
                            helperTextValue: 'worker',
                        },
                    };
                });

                setPositionHelperText('worker');
            } else if ((inputs.position.value === EmployeeRole.Admin || inputs.position.value === EmployeeRole.HeadOfWorkers || inputs.position.value === EmployeeRole.Manager) && positionHelperText !== 'owner') {
                setInputs(prev => {
                    return {
                        ...prev,
                        position: {
                            ...prev.position,
                            helperText: null,
                        },
                    };
                });

                setPositionHelperText('owner');
            } else if (shouldClear && positionHelperText !== undefined) {
                setInputs(prev => {
                    return {
                        ...prev,
                        position: {
                            ...prev.position,
                            helperText: undefined,
                        },
                    };
                });

                setPositionHelperText(undefined);
            }
        }
    }, [inputs]);

    useEffect(() => {
        const shouldShowInputs = (inputs.position.value === EmployeeRole.Worker || inputs.position.value === EmployeeRole.Admin) && inputs.contractType.hidden === true;
        if (shouldShowInputs) {
            inputs.contractType.hidden = false;
            inputs.hourlyWage.hidden = false;
        }
    }, [inputs]);

    /**
     * Submit form, but before check address.
     */
    const SubmitForm = async () => {
        if (ValidateForm(inputs, setInputs)) {
            const addressFragment = await AddressParts();

            if (addressFragment) {
                addressFragment.id = address?.id;
            }

            onSubmit({
                inputs,
                addressFragment,
            });
        }
    };

    let submitJSX = (
        <AppButton
            testId={'workerProfileFormSubmitButton'}
            className={classes.button}
            variant={"contained"}
            fullWidth={!submitRight}
            onClick={SubmitForm}
            isLoading={loading}>
            {isEdit ? tt('common.save') : tt('common.create')}
        </AppButton>
    );

    submitJSX = !submitRight ? submitJSX : (
        <div className={classes.submitRightContainer}>
            {submitJSX}
        </div>
    );

    return (
        <>
            {loadingData ? <WorkerEditScreenShimmer/> : <FormBuilder inputs={inputs} setInputs={setInputs}/>}

            {submitJSX}
        </>
    );
}

function PositionWorkerHelperTextWorker() {
    const {classes} = useStyles();

    return (
        <span>
            {tt('newWorker.popup.accessOnlyTo')}&nbsp;
            <span className={classes.bold}>{tt('newWorker.popup.mobileApplication')}</span>
        </span>
    );
}
