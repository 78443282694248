import {makeStyles} from "tss-react/mui";
import {Box, Theme} from "@mui/material";
import ChooseCompanyMenu from "./menus/ChooseCompanyMenu";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {kAppColors} from "../../styles/AppThemeProcessor";
import NotificationsMenu from "./menus/NotificationsMenu";
import AppIconButton from "./buttons/AppIconButton";
import {tt} from "../../core/Localization";
import {openSpotlight} from "@mantine/spotlight";
import Icons8Search from "../../icons/Icons8Search";
import {AppDataContext} from "../../AppData";
import {AppContext} from "../../App";
import LogoWithSubscriptionIndicator from "./progressIndicators/LogoWithSubscriptionIndicator";

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 16,
        paddingRight: 16,
        width: '100%',
        position: "sticky",
        top: 0,
        backgroundColor: kAppColors.background.mobileAppbar(theme.palette.mode === "dark"),
        backdropFilter: `saturate(180%) blur(10px)`,
        transition: 'all 0.1s linear',
        borderBottom: `1px solid transparent`,
        zIndex: 100,
    },
    mobileAppbarBorderOnScroll: {
        borderBottom: `1px solid ${kAppColors.border(theme.palette.mode === "dark")}`
    },
}));

export interface IMobileChoseCompanyAppBarProps {
    employeeId?: number | null;
    setEmployeeId?: Dispatch<SetStateAction<number | null>>;
}

export default function MobileChoseCompanyAppBar(props: IMobileChoseCompanyAppBarProps) {
    const {employeeId, setEmployeeId} = props;

    const appContext = useContext(AppContext);
    const {authUser} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, companyData} = appDataContext;

    const noCompaniesMode = !companyId && authUser && authUser.signInResponse && authUser.signInResponse.data && authUser.signInResponse.data.companyIds.length == 0;

    const [showBorderOnScroll, setBorderOnScroll] = useState(false);

    useEffect(() => {
        function handleScroll() {
            if (window.scrollY > 62) {
                if (!showBorderOnScroll) {
                    setBorderOnScroll(true);
                }
            } else {
                if (showBorderOnScroll) {
                    setBorderOnScroll(false);
                }
            }
        }

        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [window.scrollY]);

    const {classes, cx} = useStyles();
    const theAppbarClass = cx(
        classes.container,
        showBorderOnScroll ? classes.mobileAppbarBorderOnScroll : undefined,
    );

    return (
        <Box className={theAppbarClass}>
            <LogoWithSubscriptionIndicator />
            <ChooseCompanyMenu/>

            <Box ml={"auto"}/>
            {noCompaniesMode || !companyData.subscriptionIsValid ? null : (
                <>
                    <AppIconButton
                        tooltip={tt('menu.spotlight')}
                        onClick={() => openSpotlight()}
                    >
                        <Icons8Search/>
                    </AppIconButton>

                    <Box pr={1}/>
                    <NotificationsMenu isMobile={true}></NotificationsMenu>
                </>
            )}
        </Box>
    );
}
