import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import PaperAppbar from "../../components/paper/PaperAppbar";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import ContentPadding from "../../components/paper/ContentPadding";
import AppButton from "../../components/buttons/AppButton";
import {AppDataContext} from "../../../AppData";
import {
    AddressProvider,
    CompanyProfileResponse,
    GetCompanyProfileInput,
    UpdateCompanyDocument,
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
} from "../../../generated/graphql/graphql";
import {ApolloError} from "@apollo/client";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {Box, Typography} from "@mui/material";
import {Option} from "../../components/form/InputProps";
import {useNavigate} from "react-router-dom";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {kCompanyDetailRoute} from "./CompanyDetailScreen";
import {kAvailableCountries, kAvailableCurrencies} from "../../../service/CompanyService";
import CompanyEditScreenShimmer from "../../components/shimmers/screenSpecificShimmers/CompanyEditScreenShimmer";
import { FetchPolicy, RestApiClientContext } from "../../../core/RestApiProvider";
import { processQueryError } from "../../../service/ErrorService";

export const kCompanyEditRoute = '/settings/company/edit';

export default function CompanyEditScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;
    
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<CompanyProfileResponse | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            restApiGet({
                uri: '/company/profile',
                params: {
                    companyId,
                    includeIndustries: true,
                } as GetCompanyProfileInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        } else {
            setData(null);
        }
    }, [companyId]);

    const [mutateUpdate, {
        loading: updateLoading,
        error: updateError,
        data: updateData
    }] = useResettableMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument);

    useEffect(() => {
        setTitle(tt('companyDetail.screen.title'));
    }, []);

    const [inputs, setInputs] = useState<IInputsData>({
        companyName: {
            type: InputType.Text,
            label: `${tt('common.firstName')}*`,
            value: '',
            required: true,
            grid: {
                sm: 12,
                xs: 12
            }
        },
        industries: {
            type: InputType.ChipAutocomplete,
            value: [],
            options: [],
            required: true,
            label: `${tt('common.industries')}*`,
            placeholder: tt('editCompany.screen.chooseIndustryPlaceholder'),
        },
        country: {
            type: InputType.Select,
            label: `${tt('common.country')}*`,
            options: kAvailableCountries(),
            value: '',
            onChange: (index, value) => {
                updateCurrency(value);
            },
            required: true,
            grid: {
                sm: 6,
                xs: 12
            }
        },
        currency: {
            type: InputType.Select,
            label: `${tt('common.currency')}*`,
            options: kAvailableCurrencies,
            value: kAvailableCurrencies[1].value,
            required: true,
            grid: {
                sm: 6,
                xs: 12
            }
        },
        vat: {
            type: InputType.Switch,
            label: tt('vat.label.isVat'),
            value: false,
        }
    });

    function updateCurrency(val: string) {
        let currencyValue = '';

        if (val === 'us') {
            currencyValue = kAvailableCurrencies[2].value;
        } else if (val === 'cz') {
            currencyValue = kAvailableCurrencies[0].value;
        } else if (val === 'sk') {
            currencyValue = kAvailableCurrencies[1].value;
        } else {
            currencyValue = kAvailableCurrencies[1].value;
        }

        setInputs((prev: IInputsData) => {
            return {
                ...prev,
                currency: {
                    ...prev.currency,
                    value: currencyValue,
                },
            };
        });
    }

    useEffect(() => {
        const isVat = inputs.vat.value;

        setInputs((prev: IInputsData) => {
            return {
                ...prev,
                vat: {
                    ...prev.vat,
                    label: isVat ? tt('vat.label.isVat') : tt('vat.label.isNotVat'),
                },
            };
        });
    }, [inputs.vat.value]);

    useEffect(() => {
        if (data) {
            setInputs((prev: IInputsData) => {
                return {
                    ...prev,
                    companyName: {
                        ...prev.companyName,
                        value: data.company.name,
                    },
                    country: {
                        ...prev.country,
                        value: data.company.address?.country || '',
                    },
                    currency: {
                        ...prev.currency,
                        value: data.company.currency || kAvailableCurrencies[1].value,
                    },
                    industries: {
                        ...prev.industries,
                        options: data.allIndustries!.map((industry) => ({
                            value: industry.id,
                            label: tt(industry.translationKey),
                        })),
                        value: data.allIndustries!.filter((industry) => {
                            return data.company.industryIds.includes(industry.id);
                        }).map((industry) => ({
                            value: industry.id,
                            label: tt(industry.translationKey),
                        })),
                    },
                    vat: {
                        ...prev.vat,
                        value: data.company.hasVat || false,
                    }
                };
            });
        }
    }, [data]);

    /**
     * Use mutation to update Company data on BE.
     */
    const UpdateCompany = async () => {
        if (ValidateForm(inputs, setInputs)) {
            try {
                const variables: UpdateCompanyMutationVariables = {
                    input: {
                        companyId: companyId!!,
                        name: inputs.companyName.value,
                        address: {
                            id: data?.company.address?.id || 0,
                            street: data?.company.address?.street || '',
                            city: data?.company.address?.city || '',
                            country: inputs.country.value,
                            state: data?.company.address?.state || '',
                            zipcode: data?.company.address?.zipcode || '',
                            createdAt: data?.company.address?.createdAt || 0,
                            provider: AddressProvider.None,
                        },
                        industryIds: inputs.industries.value.map((item: Option) => {
                            return item.value;
                        }),
                        currency: inputs.currency.value,
                        hasVat: inputs.vat.value,
                        companySize: data?.company.companySize || '', //TODO add company size
                    },
                };

                const result = await mutateUpdate({variables});

                if (!result.errors) {
                    navigate(kCompanyDetailRoute);

                    SuccessToast(tt('companyEdit.screen.success'));
                }
            } catch (e) {
                console.error(e);
                ErrorToast(tt('common.mutation.error'));
            }
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading || updateLoading}
                dataLoading={loading}
                error={updateError}
                inputs={inputs}
                setInputs={setInputs} updateCompany={UpdateCompany}
                isMobile={isMobile}
                updateLoading={updateLoading}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}/>
    );
}

interface IBodyProps {
    dataLoading: boolean;
    loading: boolean;
    error?: ApolloError;
    inputs: IInputsData;
    setInputs: Dispatch<SetStateAction<IInputsData>>;
    updateCompany: VoidFunction;
    isMobile?: boolean;
    updateLoading?: boolean,
}

function Body(props: IBodyProps) {
    const {
        loading,
        error,
        inputs,
        setInputs,
        updateCompany,
        isMobile,
        dataLoading,
        updateLoading,
    } = props;

    const errorJsx = error ? (
        <Typography color="error" align="right" paragraph={true}>
            {tt('companyEdit.screen.error')}
        </Typography>
    ) : (
        <></>
    );

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('common.company')}
                    cancelIconBackButton={true}
                    backRoute={kCompanyDetailRoute}/>
                <ContentPadding>
                    {dataLoading ? <CompanyEditScreenShimmer/> :
                        <Box pb={2}>
                            <FormBuilder inputs={inputs} setInputs={setInputs}/>
                        </Box>
                    }

                    {errorJsx}

                    <AppButton
                        fullWidth={true}
                        variant={"contained"}
                        disabled={loading}
                        isLoading={updateLoading}
                        onClick={updateCompany}
                    >
                        {tt('common.save')}
                    </AppButton>
                </ContentPadding>
            </AppPaper>

        </ScreenContent>);
}
