import {makeStyles} from "tss-react/mui";
import {Box, CircularProgress, Theme} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {DateTime} from "luxon";
import {GetSubscriptionLeftAsPercentage, isTrialSubscription} from "../../../service/SubscriptionService";
import {SubscriptionResponse} from "../../../generated/graphql/graphql";

const useStyles = makeStyles()((theme: Theme) => ({
    progressContainer: {
        position: "relative",
        '.MuiCircularProgress-root': {
            position: "absolute",
            top: -5,
            left: -5,
            bottom: -5,
            right: -5,
        }
    }
}));

export interface IAvatarSubscriptionProgressDecorationProps {
    subscription?: SubscriptionResponse | NullOrUndefined;
    child: React.ReactElement;
}

export default function AvatarSubscriptionProgressDecoration(props: IAvatarSubscriptionProgressDecorationProps) {
    const {
        subscription,
        child
    } = props;

    const {classes} = useStyles();

    let trialPercentage = 0;

    if (subscription && isTrialSubscription(subscription)) {
        trialPercentage = GetSubscriptionLeftAsPercentage(
            DateTime.fromMillis(subscription.validFrom),
            DateTime.fromMillis(subscription.validTo),
        );
    }

    return (
        <Box className={classes.progressContainer}>
            {child}

            {trialPercentage ?
                <CircularProgress
                    sx={{color: kAppColors.trial.main}}
                    thickness={2}
                    variant={"determinate"}
                    value={trialPercentage}
                    size={65.5}
                />
                : null}
        </Box>
    );
};
