import {iconUseStyles} from "../styles/IconStyles";

export interface ISmartphoneIcons8Props {
    className?: string;
}

export default function Icons8Smartphone(props: ISmartphoneIcons8Props) {
    const {className} = props;

    const {classes: iconClasses, cx} = iconUseStyles();

    const theClassName = cx(
        iconClasses.icon,
        className,
    );

    return (
        <span className={theClassName}>
              <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48"><path
                  d="M 10.5 5 C 8.0324991 5 6 7.0324991 6 9.5 L 6 30.5 C 6 32.967501 8.0324991 35 10.5 35 L 20 35 L 20 32 L 10.5 32 C 9.6535009 32 9 31.346499 9 30.5 L 9 9.5 C 9 8.6535009 9.6535009 8 10.5 8 L 29.5 8 C 30.346499 8 31 8.6535009 31 9.5 L 31 11 L 34 11 L 34 9.5 C 34 7.0324991 31.967501 5 29.5 5 L 10.5 5 z M 26.5 13 C 24.032499 13 22 15.032499 22 17.5 L 22 38.5 C 22 40.967501 24.032499 43 26.5 43 L 37.5 43 C 39.967501 43 42 40.967501 42 38.5 L 42 17.5 C 42 15.032499 39.967501 13 37.5 13 L 26.5 13 z M 26.5 16 L 37.5 16 C 38.346499 16 39 16.653501 39 17.5 L 39 38.5 C 39 39.346499 38.346499 40 37.5 40 L 26.5 40 C 25.653501 40 25 39.346499 25 38.5 L 25 17.5 C 25 16.653501 25.653501 16 26.5 16 z M 17.5 26 C 16.672 26 16 26.671 16 27.5 C 16 28.329 16.672 29 17.5 29 L 20 29 L 20 26 L 17.5 26 z M 30.5 34 A 1.50015 1.50015 0 1 0 30.5 37 L 33.5 37 A 1.50015 1.50015 0 1 0 33.5 34 L 30.5 34 z"/>
              </svg>
        </span>
    );
}


