import {
    VisitJoinedOthersResponsePage,
    VisitStatus,
    VisitStatusModifier
} from "../../../../../generated/graphql/graphql";
import {
    getVisitStatusIcon,
    getVisitStatusModifierColor,
    getVisitStatusModifierIcon,
    getVisitStatusModifierTitle,
    getVisitStatusTextColor,
    getVisitStatusTitle, kStatusFilters, kStatusModifierFilters
} from "../../../../../service/VisitService";
import {tt} from "../../../../../core/Localization";
import FilterButton from "../../../buttons/FilterButton";
import Menu from "@mui/material/Menu";
import {bindMenu} from "material-ui-popup-state";
import {Box, Divider, MenuItem, Theme, useTheme} from "@mui/material";
import GreyLabel from "../../../decorations/GreyLabel";
import React, {useId, useMemo} from "react";
import {usePopupState} from "material-ui-popup-state/hooks";
import {makeStyles} from "tss-react/mui";
import IVisitEvent from "../../../../../model/VisitEvent";
import VisitStatusChip from "../../../chips/VisitStatusChip";


export const useStyles = makeStyles()((theme: Theme) => ({
    menuOffset: {
        '.MuiPaper-root': {
            minWidth: 200,
        },
        transform: 'translateY(8px)',
    },
    notScheduledSuffix: {
        'svg': {
            color: getVisitStatusTextColor(VisitStatus.ScheduleLater, theme.palette.mode === "dark") + ' !important',
        }
    }
}));

export interface IVisitsByStatusFilterProps {
    statusFilter: VisitStatus | undefined;
    statusModifierFilter: VisitStatusModifier | undefined;
    isDisabled?: boolean;
    SetFilter: (status?: VisitStatus, statusModifier?: VisitStatusModifier) => void;
    unfilteredVisits?: IVisitEvent[];
    visitsData?: VisitJoinedOthersResponsePage | NullOrUndefined;
    notScheduledCount?: number;
}

export default function VisitsByStatusFilter(props: IVisitsByStatusFilterProps) {
    const {
        statusModifierFilter,
        statusFilter,
        isDisabled,
        SetFilter,
        unfilteredVisits,
        visitsData,
        notScheduledCount,
    } = props;

    const {classes} = useStyles();
    const theme = useTheme();

    const statusFilterTitle = statusModifierFilter ? getVisitStatusModifierTitle(statusModifierFilter) :
        statusFilter ? getVisitStatusTitle(statusFilter) : tt('jobs.filter.status.none');

    const statusFilterMenu = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const visitStatusesOnUnfilteredVisits = useMemo(() => {
        const statuses: VisitStatus[] = [];
        const statusModifiers: VisitStatusModifier[] = [];

        if (unfilteredVisits && visitsData) {
            unfilteredVisits.forEach(visit => {
                const status = visit.visitRepeatDay?.status || visit.status;
                const statusModifier = visit.visitRepeatDay?.statusModifier || visit.statusModifier;

                if (!statuses.includes(status)) {
                    statuses.push(status);
                }

                if (status === VisitStatus.Closed && statusModifier && !statusModifiers.includes(statusModifier)) {
                    statusModifiers.push(statusModifier);
                }
            });

            if ((visitsData?.scheduleLater || []).length) {
                statuses.push(VisitStatus.ScheduleLater);
            }
        }

        return {
            statuses,
            statusModifiers,
            ongoing: statuses.filter(status => kStatusFilters.includes(status)).length > 0,
            finished: statuses.includes(VisitStatus.Closed) || statuses.includes(VisitStatus.Canceled) || statusModifiers.length,
        };
    }, [unfilteredVisits, visitsData]);

    return (
        <>
            <FilterButton
                isSelected={!!statusFilter}
                title={statusFilterTitle}
                onClick={(e) => {
                    statusFilterMenu.setOpen(true, e);
                }}
                isDisabled={isDisabled}
                suffix={notScheduledCount && statusFilter !== VisitStatus.ScheduleLater ? (
                    <Box className={classes.notScheduledSuffix}>
                        <VisitStatusChip
                            status={VisitStatus.ScheduleLater}
                            customValue={notScheduledCount}/>
                    </Box>
                ) : null}
            />

            <Menu className={classes.menuOffset}  {...bindMenu(statusFilterMenu)}>
                <MenuItem
                    key={'All'}
                    onClick={() => {
                        statusFilterMenu.setOpen(false);

                        SetFilter(undefined);
                    }
                    }
                >
                    {tt('jobs.filter.status.all')}
                </MenuItem>

                {visitStatusesOnUnfilteredVisits.ongoing ? (
                    <Box pl={1} pr={1} pt={1.25} pb={1.25}>
                        <GreyLabel text={tt('dashboardScreen.tab.ongoing')}/>
                    </Box>
                ) : undefined}

                {kStatusFilters
                    .filter(filterType => visitStatusesOnUnfilteredVisits.statuses.includes(filterType))
                    .map(filterType => (
                        <MenuItem
                            key={filterType}
                            onClick={() => {
                                statusFilterMenu.setOpen(false);

                                SetFilter(filterType);
                            }
                            }
                        >
                            <Box
                                sx={{color: getVisitStatusTextColor(filterType, theme.palette.mode === "dark")}}>
                                {getVisitStatusIcon(filterType)}
                            </Box>{getVisitStatusTitle(filterType)}
                        </MenuItem>
                    ))}

                {visitStatusesOnUnfilteredVisits.ongoing && visitStatusesOnUnfilteredVisits.finished ? (
                    <Divider sx={{
                        mr: -2, ml: -2,
                    }}/>
                ) : undefined}

                {visitStatusesOnUnfilteredVisits.finished ? (
                    <Box pl={1} pr={1} pt={1.25} pb={1.25}>
                        <GreyLabel text={tt('dashboardScreen.tab.finished')}/>
                    </Box>
                ) : undefined}

                {visitStatusesOnUnfilteredVisits.statuses.includes(VisitStatus.Closed) ? (
                    <MenuItem
                        key={VisitStatus.Closed}
                        onClick={() => {
                            statusFilterMenu.setOpen(false);

                            SetFilter(VisitStatus.Closed);
                        }
                        }
                    >
                        <Box
                            sx={{color: getVisitStatusTextColor(VisitStatus.Closed, theme.palette.mode === "dark")}}>
                            {getVisitStatusIcon(VisitStatus.Closed)}
                        </Box>{getVisitStatusTitle(VisitStatus.Closed)}
                    </MenuItem>
                ) : undefined}

                {kStatusModifierFilters
                    .filter(filterType => {
                        return visitStatusesOnUnfilteredVisits.statuses.includes(VisitStatus.Closed) && visitStatusesOnUnfilteredVisits.statusModifiers.includes(filterType);
                    })
                    .map(filterType => (
                        <MenuItem
                            key={filterType}
                            onClick={() => {
                                statusFilterMenu.setOpen(false);

                                SetFilter(VisitStatus.Closed, filterType);
                            }
                            }
                        >
                            <Box
                                sx={{color: getVisitStatusModifierColor(filterType)}}>
                                {getVisitStatusModifierIcon(filterType)}
                            </Box>
                            {getVisitStatusModifierTitle(filterType)}
                        </MenuItem>
                    ))}

                {visitStatusesOnUnfilteredVisits.statuses.includes(VisitStatus.Canceled) ? (
                    <MenuItem
                        key={VisitStatus.Canceled}
                        onClick={() => {
                            statusFilterMenu.setOpen(false);

                            SetFilter(VisitStatus.Canceled);
                        }
                        }
                    >
                        <Box
                            sx={{color: getVisitStatusTextColor(VisitStatus.Canceled, theme.palette.mode === "dark")}}>
                            {getVisitStatusIcon(VisitStatus.Canceled)}
                        </Box>
                        {getVisitStatusTitle(VisitStatus.Canceled)}
                    </MenuItem>
                ) : undefined}
            </Menu>
        </>
    );
}
