import React, {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../AppData";
import {CompanyEmployeeProcessingDataResponse, EmployeeRole, GetCompanyInput} from "../generated/graphql/graphql";
import {tt} from "./Localization";
import OnboardingScreenWidget from "../ui/components/onboarding/OnboardingScreenWidget";
import DownloadAppScreenWidget from "../ui/components/onboarding/DownloadAppScreenWidget";
import {SuccessToast} from "../service/ToastService";
import {SetLoaderModal} from "../ui/components/modals/AppModals";
import {UserSetCompany} from "../service/UserService";
import {AppContext} from "../App";
import {currencyCodeToLabel} from "../service/CompanyService";
import {useNavigate} from "react-router-dom";
import IEventSystemListener from "../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../service/EventSystemService";
import IEventSystemNotification from "../model/firestore/EventSystemNotification";
import {kDashboardRoute} from "../ui/screens/dashboard/DashboardScreen";
import {AuthUserUpdateCompanyIds} from "../service/AuthUserService";
import {processQueryError} from "../service/ErrorService";
import {transitionalRoute} from "../ui/screens/TransitionalScreen";
import {RestApiClientContext} from "./RestApiProvider";
import {kActionUpdate, kTopicCompanies, kTopicCompanyEmployees, kTopicUsers} from "./constants";
import {isTrialSubscription, isValidSubscription} from "../service/SubscriptionService";
import InvalidCompanySubscriptionWidget from "../ui/components/company/InvalidCompanySubscriptionWidget";

export interface ICompanyProcessorProps {
    children: React.ReactNode;
    whitelistedRoute?: boolean;
}

/**
 * Component for processing User/Company and Employee role for proper states (Onboarding, etc.).
 */
export default function CompanyProcessor(props: ICompanyProcessorProps) {
    const {children, whitelistedRoute} = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {authUser} = appContext;
    const userId = authUser?.signInResponse?.data?.userId;

    const appDataContext = useContext(AppDataContext);
    const {
        companyId,
        setCurrency,
        switchToCompanyId,
        companyData,
        setCompanyData,
        company,
        setCompany,
        switchToCompanyMessage,
        employeeId,
        setEmployeeId,
        companyEmployeeRole,
        setCompanyEmployeeRole,
        employeePermissionsMap,
        setEmployeePermissionsMap,
        setCompanySubscriptionStatus,
        userPreferences,
        setUserPreferences
    } = appDataContext;

    const [companyReady, setCompanyReady] = useState(false);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<CompanyEmployeeProcessingDataResponse | NullOrUndefined>();
    const [refetch, setRefetch] = useState(0);
    useEffect(() => {
        if (companyId) {
            const subscription = subscribe(
                kTopicUsers,
                {
                    uri: '/company/employee-processing-data',
                    params: {
                        companyId,
                    } as GetCompanyInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => {
                    return notifications.some(notification => {
                        if (notification.action !== kActionUpdate) {
                            return false;
                        }

                        return notification.data.id === userId;
                    });
                },
            );

            return () => {
                subscription.cancel();
            };
        } else {
            setData(undefined);
        }
    }, [companyId, authUser, userId, refetch]);

    const companyName = data?.company.name;
    const newCurrency = data?.company.currency;
    const employee = data?.employee;
    const companySubscriptionStatus = data?.subscription;
    const dataUserPreferences = data?.userPreferences;

    useEffect(() => {
        if (companyId === undefined) {
            setTimeout(() => {
                UserSetCompany(appDataContext, switchToCompanyId);
            }, 75);
        } else if (switchToCompanyId !== companyId) {
            SetLoaderModal(appDataContext, {
                open: true,
                text: `${switchToCompanyMessage || tt('switching.to.company')}...`,
            });

            setTimeout(() => {
                UserSetCompany(appDataContext, switchToCompanyId);
            }, 75);

            setTimeout(() => {
                SetLoaderModal(appDataContext, {
                    open: false,
                });
            }, 1400);
        }
    }, [switchToCompanyId]);

    useEffect(() => {
        if (companyName) {
            SetLoaderModal(appDataContext, {
                text: `${switchToCompanyMessage || tt('switching.to.company')} ${companyName}...`,
            });

            if (!switchToCompanyMessage && appDataContext.appModals?.fullScreenLoader?.open) {
                setTimeout(() => {
                    SuccessToast(`${tt('switched.to.company')} ${companyName}`);
                }, 1200);
            }
        }
    }, [companyName]);

    useEffect(() => {
        if (newCurrency) {
            const newCurrencyLabel = currencyCodeToLabel(newCurrency);

            setCurrency(newCurrencyLabel);
        }
    }, [newCurrency]);

    useEffect(() => {
        setCompanySubscriptionStatus(companySubscriptionStatus);

        setCompanyData({
            subscriptionIsValid: isValidSubscription(companySubscriptionStatus?.subscription),
            subscriptionIsTrial: isTrialSubscription(companySubscriptionStatus?.subscription),
            employee,
        });
    }, [companySubscriptionStatus, employee]);

    useEffect(() => {
        if (data) {
            if (employeeId !== data.employee.id) {
                setEmployeeId(data.employee.id);
            }
            if (companyEmployeeRole !== data.employee.role) {
                setCompanyEmployeeRole(data.employee.role);
            }

            const permissionsMap = JSON.parse(data.permissionsMapJSON);
            setEmployeePermissionsMap(permissionsMap);
        } else {
            setEmployeeId(undefined);
            setCompanyEmployeeRole(undefined);
            setEmployeePermissionsMap(undefined);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            setCompany(data.company);
        } else {
            setCompany(undefined);
        }
    }, [data]);

    useEffect(() => {
        if (data && !companyReady) {
            setCompanyReady(true);
        }
    }, [data]);
    useEffect(() => {
        if (authUser?.signInResponse?.data && authUser!.signInResponse!.data.companyIds.length === 0 && !companyReady) {
            setCompanyReady(true);
        }
    }, [authUser]);

    useEffect(() => {
        setUserPreferences(dataUserPreferences || userPreferences);
    }, [dataUserPreferences]);

    if (!companyReady) {
        return null;
    }

    if (!companyId && !whitelistedRoute) {
        return (
            <>
                <OnboardingScreenWidget/>

                <EventSystemListeners
                    refetch={() => setRefetch(refetch + 1)}
                    role={companyEmployeeRole}
                />
            </>
        );
    }

    if (!companyData.subscriptionIsValid && !whitelistedRoute) {
        return (
            <>
                <InvalidCompanySubscriptionWidget isTrial={!!companyData.subscriptionIsTrial} />

                <EventSystemListeners
                    refetch={() => setRefetch(refetch + 1)}
                    role={companyEmployeeRole}
                />
            </>
        );
    }

    if (data && data.employee.role === EmployeeRole.Worker && !whitelistedRoute) {
        return (
            <>
                <DownloadAppScreenWidget/>

                <EventSystemListeners
                    refetch={() => setRefetch(refetch + 1)}
                    role={companyEmployeeRole}
                />
            </>
        );
    }

    return (
        <>
            {children}

            <EventSystemListeners
                refetch={() => setRefetch(refetch + 1)}
                role={companyEmployeeRole}
            />
        </>
    );
}

interface IEventSystemListenersProps {
    refetch: VoidFunction;
    role: EmployeeRole | NullOrUndefined;
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {refetch, role} = props;

    const appContext = useContext(AppContext);

    const appDataContext = useContext(AppDataContext);
    const {companyId, switchToCompanyId, employeeId, processing} = appDataContext;
    const {deleteCompany} = processing;

    const navigate = useNavigate();

    useEffect(() => {
        if (companyId) {
            const eventSystemListener: IEventSystemListener = {
                topic: kTopicCompanies,
                callback: (notifications: IEventSystemNotification[]) => {
                    const update = notifications.some(notification => {
                        return notification.data.id == companyId;
                    });

                    if (update) {
                        refetch();
                    }
                },
            };

            listenToEventSystem(eventSystemListener);

            return () => {
                unListenToEventSystem(eventSystemListener);
            };
        }
    }, [companyId, refetch]);

    useEffect(() => {
        if (companyId && role) {
            const eventSystemListener: IEventSystemListener = {
                topic: kTopicCompanyEmployees,
                callback: (notifications: IEventSystemNotification[]) => {
                    const update = notifications.some(notification => {
                        return notification.data.role === role && notification.action === kActionUpdate
                            || notification.data.id === employeeId;
                    });

                    if (update) {
                        refetch();
                    }
                },
            };

            listenToEventSystem(eventSystemListener);

            return () => {
                unListenToEventSystem(eventSystemListener);
            };
        }
    }, [companyId, employeeId, role, refetch]);

    useEffect(() => {
        if (companyId && employeeId) {
            const eventSystemListener: IEventSystemListener = {
                topic: kTopicCompanyEmployees,
                callback: (notifications: IEventSystemNotification[]) => {
                    const update = notifications.some(notification => {
                        return notification.data.layoffCompanyEmployeeId === employeeId || notification.data.deleteCompanyEmployeeId === employeeId;
                    });

                    if (update) {
                        if (!deleteCompany) {
                            navigate(transitionalRoute(kDashboardRoute));
                        }

                        AuthUserUpdateCompanyIds(
                            appContext,
                            appDataContext,
                            {
                                remove: companyId,
                                removeOnlyNoActions: deleteCompany,
                            },
                        );
                    }
                },
            };

            listenToEventSystem(eventSystemListener);

            return () => {
                unListenToEventSystem(eventSystemListener);
            };
        }
    }, [appContext, appDataContext, companyId, employeeId, deleteCompany, refetch]);

    return null;
}
