import React, {useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {AppDataContext} from "../../../AppData";
import {
    EmployeePermissionsResponse,
    EmployeePermissionsResponsePage,
    EmployeeRole,
    GetPermissionsForAllRolesInput,
    SavePermissionsForRoleInput
} from "../../../generated/graphql/graphql";
import {tt} from "../../../core/Localization";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {kCompanyDetailRoute} from "./CompanyDetailScreen";
import {Box} from "@mui/material";
import {getBackRoute} from "../../../utils/Utils";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import AppTabsComponent from "../../components/AppTabsComponent";
import {UserRoleTitle} from "../../../service/UserService";
import PermissionsSection from "../../components/permissions/PermissionsSection";
import {kTopicCompanies} from "../../../core/constants";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {HideEditPermissionsModal, SetEditPermissionsModal} from "../../components/modals/AppModals";
import {updatePermissionsMapJSON, updatePermissionsMapJSONForOption} from "../../../service/PermissionsService";
import {SuccessToast} from "../../../service/ToastService";
import {IUpdatePermissionsParams} from "../../components/permissions/EditPermissionsModal";

export const kCompanyPermissionsRoute = '/settings/company/permissions';

export default function CompanySettingsScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe, restApiPost} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, appModals} = appDataContext;

    useEffect(() => {
        setTitle(tt('companyPermissions.screen.title'));
    }, []);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<EmployeePermissionsResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (companyId) {
            setLoading(true);

            const subscription = subscribe(
                kTopicCompanies,
                {
                    uri: '/company/permissions-templates',
                    params: {
                        companyId,
                    } as GetPermissionsForAllRolesInput,
                    setLoading,
                    onData: setData,
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                (notifications: IEventSystemNotification[]) => true,
            );

            return () => {
                subscription.cancel();
            };
        } else {
            setData(null);
        }
    }, [companyId]);

    /**
     * Update permission values to BE.
     */
    const updatePermissions = (params: IUpdatePermissionsParams) => {
        const {permission, option, role, applyForAllOfRole, otherPermissions, updatedPermissions} = params;

        try {
            SetEditPermissionsModal(
                appDataContext,
                {
                    ...appModals?.editPermissions,
                    updateLoading: true,
                },
            );

            const permissionsForRole = data?.content.find(item => item.role === role)!;

            let permissionsMapJSON = option !== null ? updatePermissionsMapJSONForOption(permission, option, permissionsForRole.permissionsMapJSON) : permissionsForRole.permissionsMapJSON;

            if (otherPermissions) {
                for (const [key, value] of Object.entries(otherPermissions)) {
                    permissionsMapJSON = updatePermissionsMapJSON(key, value, permissionsMapJSON);
                }
            }

            restApiPost({
                uri: '/company/permissions-template',
                params: {
                    companyId,
                    role,
                    permissionsMapJSON,
                    applyForAllOfRole: !!applyForAllOfRole,
                    updatedPermissions,
                } as SavePermissionsForRoleInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: (value) => SetEditPermissionsModal(
                    appDataContext,
                    {
                        ...appModals?.editPermissions,
                        updateLoading: value,
                    },
                ),
                onData: (data) => {
                    if (data) {
                        SuccessToast(tt('companyPermissions.screen.update.success'));

                        HideEditPermissionsModal(appDataContext);
                    }
                },
                onError: (error: any) => processMutationError(error),
            });
        } catch (e) {
            processMutationError(e);

            SetEditPermissionsModal(
                appDataContext,
                {
                    ...appModals?.editPermissions,
                    updateLoading: false,
                },
            );
        }
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                loading={loading}
                data={data?.content}
                isMobile={isMobile}
                updatePermissions={updatePermissions}
            />
        );
    }

    return (
        <>
            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
        </>
    );
}

interface IBodyProps {
    loading: boolean;
    data?: EmployeePermissionsResponse[];
    isMobile?: boolean;
    updatePermissions: (params: IUpdatePermissionsParams) => void;
}

function Body(props: IBodyProps) {
    const {
        loading,
        data,
        isMobile,
        updatePermissions,
    } = props;

    const contentJSX = useMemo(() => {
        const admin = data?.find(item => item.role === EmployeeRole.Admin);
        const manager = data?.find(item => item.role === EmployeeRole.Manager);
        const headOfWorkers = data?.find(item => item.role === EmployeeRole.HeadOfWorkers);
        const worker = data?.find(item => item.role === EmployeeRole.Worker);

        return loading && !data ? (
            <DetailScreenShimmer/>
        ) : (
            <AppTabsComponent
                data={[
                    {
                        label: UserRoleTitle(EmployeeRole.Admin),
                        content: (
                            <PermissionsSection
                                role={EmployeeRole.Admin}
                                employeePermissionsMap={JSON.parse(admin!.permissionsMapJSON)}
                                applyForAllOfRole={EmployeeRole.Admin}
                                updatePermissions={updatePermissions}
                            />
                        ),
                    },
                    {
                        label: UserRoleTitle(EmployeeRole.Manager),
                        content: (
                            <PermissionsSection
                                role={EmployeeRole.Manager}
                                employeePermissionsMap={JSON.parse(manager!.permissionsMapJSON)}
                                applyForAllOfRole={EmployeeRole.Manager}
                                updatePermissions={updatePermissions}
                            />
                        )
                    },
                    {
                        label: UserRoleTitle(EmployeeRole.HeadOfWorkers),
                        content: (
                            <PermissionsSection
                                role={EmployeeRole.HeadOfWorkers}
                                employeePermissionsMap={JSON.parse(headOfWorkers!.permissionsMapJSON)}
                                applyForAllOfRole={EmployeeRole.HeadOfWorkers}
                                updatePermissions={updatePermissions}
                            />
                        )
                    },
                    {
                        label: UserRoleTitle(EmployeeRole.Worker),
                        content: (
                            <PermissionsSection
                                role={EmployeeRole.Worker}
                                employeePermissionsMap={JSON.parse(worker!.permissionsMapJSON)}
                                applyForAllOfRole={EmployeeRole.Worker}
                                updatePermissions={updatePermissions}
                            />
                        )
                    }
                ]}
            />
        );
    }, [loading, data]);

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar
                    backRoute={getBackRoute(kCompanyDetailRoute)}
                    cancelIconBackButton={true}
                    isMobile={isMobile}
                    title={tt('companyPermissions.screen.title')}
                />

                {contentJSX}

                <Box pb={2}/>
            </AppPaper>
        </ScreenContent>
    );
}
