import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ScreenContent from "../../components/screens/ScreenContent";
import {kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {kSettingsRoute} from "./SettingsScreen";
import {Box} from "@mui/material";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import AppButton from "../../components/buttons/AppButton";
import AppPaper from "../../components/paper/AppPaper";
import {AppDataContext} from "../../../AppData";
import {kUserPreferencesAllCompanyNotifications, kUserPreferencesNotificationsVisitStatusToSolve} from "../../../core/constants";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {SaveUserPreferencesItemsForKeysInput} from "../../../generated/graphql/graphql";
import {SuccessToast} from "../../../service/ToastService";
import {processMutationError} from "../../../service/ErrorService";
import {useNavigate} from "react-router-dom";

export const kNotificationsSettingsRoute = '/settings/notifications';

/**
 * Screen component for settings of User notifications.
 */
export default function NotificationsSettingsScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId} = appDataContext;

    const navigate = useNavigate();

    useEffect(() => {
        setTitle(tt('notificationsSettings.screen.title'));
    }, []);

    const [loading, setLoading] = useState<boolean>(false);
    /**
     * Save timesheets related settings to UserPreferences on BE.
     */
    const saveSettings = (inputs: IInputsData) => {
        setLoading(true);

        restApiPost({
            uri: '/user-preferences/user-preferences-items-for-keys',
            params: {
                companyId,
                items: [
                    {key: kUserPreferencesAllCompanyNotifications, valueBool: inputs.allCompanyNotifications.value},
                    {key: kUserPreferencesNotificationsVisitStatusToSolve, valueBool: inputs.notificationsJobEmployeeStatusCancelled.value},
                ],
            } as SaveUserPreferencesItemsForKeysInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading,
            onData: (data) => {
                if (data) {
                    SuccessToast(tt('notificationsSettings.screen.success'));

                    navigate(kSettingsRoute);
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMobile={isMobile}
                saveSettings={saveSettings}
                saveLoading={loading}
            />
        );
    }

    return (
        <ResponsiveContainer
            smallPhoneScreen={bodyJSX(true)}
            largePhoneScreen={bodyJSX(true)}
            tabletScreen={bodyJSX()}
            smallDesktopScreen={bodyJSX()}
            largeDesktopScreen={bodyJSX()}
            extraLargeDesktopScreen={bodyJSX()}/>
    );
}

interface IBodyProps {
    isMobile?: boolean;
    saveSettings: (inputs: IInputsData) => void;
    saveLoading: boolean;
}

/**
 * Body component for settings of User notifications.
 */
function Body(props: IBodyProps) {
    const {isMobile, saveSettings, saveLoading} = props;

    const appDataContext = useContext(AppDataContext);
    const {userPreferences} = appDataContext;

    const [canSetValues, setCanSetValues] = useState<boolean>(true);

    const [inputs, setInputs] = useState<IInputsData>({
        allCompanyNotifications: {
            type: InputType.Switch,
            label: tt('notificationsSettings.allCompanyNotifications.label'),
            topLabel: tt('notificationsSettings.allCompanyNotifications.topLabel'),
            value: false,
            switchVariant: "Android12Switch",
        },
        notificationsVisitStatusToSolve: {
            type: InputType.Switch,
            label: tt('notificationsSettings.notificationsVisitStatusToSolve.label'),
            topLabel: tt('notificationsSettings.group.visits.label'),
            value: false,
            switchVariant: "Android12Switch",
        },
    });

    useEffect(() => {
        if (userPreferences.length > 0) {
            const allCompanyNotifications = userPreferences.find(item => item.key === kUserPreferencesAllCompanyNotifications);
            const notificationsVisitStatusToSolve = userPreferences.find(item => item.key === kUserPreferencesNotificationsVisitStatusToSolve);

            if ((allCompanyNotifications || notificationsVisitStatusToSolve) && canSetValues) {
                setCanSetValues(false);

                setInputs({
                    ...inputs,
                    allCompanyNotifications: {
                        ...inputs.allCompanyNotifications,
                        value: allCompanyNotifications?.valueBool || false,
                    },
                    notificationsVisitStatusToSolve: {
                        ...inputs.notificationsVisitStatusToSolve,
                        value: notificationsVisitStatusToSolve?.valueBool || false,
                    },
                });
            }
        }
    }, [userPreferences]);

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}
        >
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('notificationsSettings.screen.title')}
                    cancelIconBackButton={true}
                    backRoute={kSettingsRoute}
                />
                <Box pl={2} pr={2} pb={2}>
                    <FormBuilder
                        inputs={inputs}
                        setInputs={setInputs}
                    />

                    <Box pb={4}/>

                    <AppButton
                        fullWidth={true}
                        variant={"contained"}
                        isLoading={saveLoading}
                        onClick={() => {
                            if (ValidateForm(inputs, setInputs)) {
                                saveSettings(inputs);
                            }
                        }}
                    >{tt('common.save')}</AppButton>
                </Box>
            </AppPaper>
        </ScreenContent>
    );
}
