import {IAppContext} from "../App";
import {
    FacebookAuthProvider,
    fetchSignInMethodsForEmail,
    getAuth,
    getRedirectResult,
    GoogleAuthProvider,
    linkWithCredential,
    OAuthCredential,
    OAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    User
} from "firebase/auth";
import {DateTime} from "luxon";
import {LoginProvider} from "../generated/graphql/graphql";
import {AppDataReset, IAppDataContext} from "../AppData";
import {tt} from "../core/Localization";
import {ErrorToast, SuccessToast} from "./ToastService";
import React from "react";
import {UserCompany, UserStartSwitchCompany} from "./UserService";
import { SetLinkProvidersModal } from "../ui/components/modals/AppModals";

export const kStorageAutoSignInRefresh = 'authUser_auto_refresh';
export const kMinutesBetweenAutoSignInRefresh = 30;
export const kStoragePendingProvider = 'authUser_pending_provider';

/**
 * SignIn with socials using redirect method.
 */
export function AuthUserSignInSocial(
    appDataContext: IAppDataContext,
    provider: LoginProvider,
    alreadyLinking?: boolean,
) {
    const {language} = appDataContext;

    const auth = getAuth();
    auth.languageCode = language;

    try {
        if (provider === LoginProvider.Google) {
            const theProvider = new GoogleAuthProvider();
            theProvider.addScope('email');
            theProvider.addScope('profile');

            signInWithRedirect(auth, theProvider);
        }

        if (provider === LoginProvider.Facebook) {
            const theProvider = new FacebookAuthProvider();
            theProvider.addScope('email');
            theProvider.addScope('public_profile');

            signInWithRedirect(auth, theProvider);
        }

        if (provider === LoginProvider.Apple) {
            const theProvider = new OAuthProvider('apple.com');
            theProvider.addScope('email');
            theProvider.addScope('name');

            theProvider.setCustomParameters({
                locale: language
            });

            signInWithRedirect(auth, theProvider);
        }
    } catch (e) {
        console.error(e);

        FirebaseAuthErrorMessage(e);

        if (FirebaseAuthErrorMessage(e, true) && !alreadyLinking) {
            LinkAuthProvidersStart(appDataContext, e);
        }
    }
}

/**
 * SignIn with socials using popup method.
 */
export async function AuthUserSignInSocialPopup(
    appDataContext: IAppDataContext,
    provider: LoginProvider,
) {
    const {language} = appDataContext;

    const auth = getAuth();
    auth.languageCode = language;

    try {
        if (provider === LoginProvider.Google) {
            const theProvider = new GoogleAuthProvider();
            theProvider.addScope('email');
            theProvider.addScope('profile');

            const result = await signInWithPopup(auth, theProvider);
        }

        if (provider === LoginProvider.Facebook) {
            const theProvider = new FacebookAuthProvider();
            theProvider.addScope('email');
            theProvider.addScope('public_profile');

            const result = await signInWithPopup(auth, theProvider);
        }

        if (provider === LoginProvider.Apple) {
            const theProvider = new OAuthProvider('apple.com');
            theProvider.addScope('email');
            theProvider.addScope('name');

            theProvider.setCustomParameters({
                locale: language
            });

            const result = await signInWithPopup(auth, theProvider);
        }
    } catch (e) {
        console.error(e);

        FirebaseAuthErrorMessage(e);
    }
}

/**
 * SignIn with socials process result from redirect method.
 */
export async function AuthUserSignInSocialRedirectResult(appDataContext: IAppDataContext) {
    try {
        const authResult = await getRedirectResult(getAuth());

        if (authResult) {
            // do nothing
        }
    } catch (e) {
        console.error(e);

        if (FirebaseAuthErrorMessage(e, true)) {
            LinkAuthProvidersStart(appDataContext, e);
        }
    }
}

/**
 * On auth/account-exists-with-different-credential start process of linking providers.
 */
export function LinkAuthProvidersStart(appDataContext: IAppDataContext, e: any) {
    const pendingCredential = OAuthProvider.credentialFromError(e as any)!;

    localStorage.setItem(
        kStoragePendingProvider,
        JSON.stringify(pendingCredential),
    );

    SetLinkProvidersModal(appDataContext, {
        open: true,
        hideProvider: FirebaseProviderToEnum(pendingCredential.providerId),
    });
}

/**
 * Delete pending credential from storage.
 */
export function LinkAuthProvidersCancel() {
    localStorage.removeItem(kStoragePendingProvider);
}

/**
 * Finish linking providers if pending credential exist.
 */
export async function LinkAuthProvidersFinish(user: User) {
    try {
        const pendingCredential = localStorage.getItem(kStoragePendingProvider);

        if (pendingCredential) {
            localStorage.removeItem(kStoragePendingProvider);

            const theCredential = OAuthProvider.credentialFromJSON(pendingCredential);
            
            let result = await linkWithCredential(user, theCredential);

            SuccessToast(tt('firebaseAuth.success.anotherProvider.linking.success'));
        }
    } catch (e) {
        console.error(e);

        ErrorToast(tt('firebaseAuth.error.anotherProvider.linking.failed'));
    }
}

/**
 * SignOut current user from Firebase.
 */
export async function AuthUserSignOut(appContext: IAppContext, appDataContext: IAppDataContext) {
    const {setAuthUser} = appContext;

    try {
        await getAuth().signOut();

        setAuthUser(undefined);

        AppDataReset(appDataContext);
    } catch (e) {
        console.error(e);
    }
}

/**
 * Check if should refresh idToken by date.
 */
export function AuthUserCanRefreshToken(): boolean {
    const storedDate = localStorage.getItem(kStorageAutoSignInRefresh);

    if (storedDate) {
        const refreshedAt = DateTime.fromMillis(parseFloat(storedDate));

        const diff = DateTime.now().diff(refreshedAt, ['minutes']);

        if (diff.minutes < kMinutesBetweenAutoSignInRefresh) {
            return false;
        }
    }

    return true;
}

/**
 * Store idToken refresh date.
 */
export function AuthUserRefreshedToken() {
    localStorage.setItem(kStorageAutoSignInRefresh, `${DateTime.now().toMillis()}`);
}

/**
 * Convert providerId from Firebase to our Enum.
 */
export function FirebaseProviderToEnum(providerId: string): LoginProvider {
    switch (providerId) {
        case 'password':
            return LoginProvider.Email;
        case 'google.com':
            return LoginProvider.Google;
        case 'facebook.com':
            return LoginProvider.Facebook;
        case 'apple.com':
            return LoginProvider.Apple;
        default:
            throw Error(`Not supported Firebase providerId(${providerId})`);
    }
}

/**
 * Display Firebase auth error.
 * Return if should signIn with different provider.
 */
export function FirebaseAuthErrorMessage(e: any | undefined, accountExistsMsg?: boolean): boolean {
    if (!e || !e.code) {
        ErrorToast(tt('firebaseAuth.error'));
    }

    if (e.code === 'auth/email-already-in-use') {
        ErrorToast(tt('firebaseAuth.error.emailUsed'));
    } else if (e.code === 'auth/invalid-email') {
        ErrorToast(tt('firebaseAuth.error.invalidEmail'));
    } else if (e.code === 'auth/weak-password') {
        ErrorToast(tt('firebaseAuth.error.weakPassword'));
    } else if (e.code === 'auth/wrong-password') {
        ErrorToast(tt('firebaseAuth.error.wrongPassword'));
    } else if (e.code === 'auth/account-exists-with-different-credential') {
        if (!accountExistsMsg) {
            ErrorToast(tt('firebaseAuth.error.anotherProvider'));
        }

        return true;
    } else {
        ErrorToast(tt('firebaseAuth.error'));
    }

    return false;
}

export interface IAuthUserUpdateCompanyIdsProps {
    add?: number;
    remove?: number;
    removeOnlyNoActions?: boolean;
}

/**
 * Update AuthUser Company ids by add or remove id.
 */
export function AuthUserUpdateCompanyIds(appContext: IAppContext, appDataContext: IAppDataContext, props: IAuthUserUpdateCompanyIdsProps) {
    const {add, remove, removeOnlyNoActions} = props;
    const {authUser, setAuthUser} = appContext;
    const {setCompanyId} = appDataContext;

    if (authUser?.signInResponse?.data) {
        let companyIds = authUser!.signInResponse!.data!.companyIds;

        if (add && !companyIds.includes(add)) {
            companyIds.push(add);
        }

        if (remove && companyIds.includes(remove)) {
            let newCompanyIds: number[] = [];

            for (const id of companyIds) {
                if (id !== remove) {
                    newCompanyIds.push(id);
                }
            }

            companyIds = newCompanyIds;
        }

        setAuthUser(prev => {
            if (prev) {
                let response = prev.signInResponse;

                if (response) {
                    response = {
                        ...response,
                        data: {
                            ...response.data!,
                            companyIds,
                        },
                    };
                }

                if (remove && !removeOnlyNoActions) {
                    setTimeout(() => {
                        const newCompanyId = UserCompany(companyIds);

                        if (newCompanyId) {
                            UserStartSwitchCompany(appDataContext, newCompanyId);
                        } else {
                            setCompanyId(newCompanyId);
                        }
                    }, 1);
                }

                return {
                    ...prev,
                    signInResponse: response,
                };
            } else {
                setTimeout(() => {
                    setCompanyId(undefined);
                }, 1);

                return undefined;
            }
        });
    }
}
