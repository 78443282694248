import React, {useContext, useEffect, useId, useMemo, useState} from "react";
import {AppContext} from "../../../App";
import {tt} from "../../../core/Localization";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/paper/AppPaper";
import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import PaperAppbar from "../../components/paper/PaperAppbar";
import PencilIcon from "../../../icons/PencilIcon";
import AppIconButton from "../../components/buttons/AppIconButton";
import MoreFilledIcon from "../../../icons/MoreFilledIcon";
import {makeStyles} from "tss-react/mui";
import {Box, Divider, Theme, Tooltip} from "@mui/material";
import AppListItem from "../../components/listItems/AppListItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {usePopupState} from "material-ui-popup-state/hooks";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import UserStatusRow from "../../screenSections/userStatussRow/UserStatusRow";
import ContactSection from "../../screenSections/contactSection/ContactSection";
import AppTabsComponent from "../../components/AppTabsComponent";
import {useNavigate, useParams} from "react-router-dom";
import {kAppColors, kContentWidthMedium} from "../../../styles/AppThemeProcessor";
import DetailScreenShimmer from "../../components/shimmers/DetailScreenShimmer";
import {kWorkerEditRoute} from "./WorkerEditScreen";
import {
    CreateOrExistingCompanyEmployeePasscodeDocument,
    CreateOrExistingCompanyEmployeePasscodeMutation,
    CreateOrExistingCompanyEmployeePasscodeMutationVariables,
    DeleteCompanyEmployeeDocument,
    DeleteCompanyEmployeeMutation,
    DeleteCompanyEmployeeMutationVariables,
    DeleteEmployeePermissionsInput,
    EmployeeDetailResponse,
    EmployeeRole,
    GetEmployeeDetailInput,
    LayoffCompanyEmployeeDocument,
    LayoffCompanyEmployeeMutation,
    LayoffCompanyEmployeeMutationVariables,
    ReActivateCompanyEmployeeDocument,
    ReActivateCompanyEmployeeMutation,
    ReActivateCompanyEmployeeMutationVariables,
    SavePermissionsForEmployeeInput,
    SendNotificationToUsersDocument,
    SendNotificationToUsersMutation,
    SendNotificationToUsersMutationVariables
} from "../../../generated/graphql/graphql";
import {useMutation} from "@apollo/client";
import {
    AddressMapsLink,
    UserAddressSingle,
    UserFullName,
    UserPhotoUrl,
    UserRoleTitle
} from "../../../service/UserService";
import {useResettableMutation} from "tomaschyly-apollo-hooks-extended";
import {AppDataContext} from "../../../AppData";
import {
    HideConfirmModal,
    HideEditPermissionsModal,
    SetConfirmModal,
    SetConfirmModalLoading,
    SetEditPermissionsModal
} from "../../components/modals/AppModals";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {kWorkersRoute} from "./WorkersScreen";
import {ContractTypeString} from "../../../service/EmployeeService";
import InviteWorkerModalBottomSheet from "../../components/modals/InviteWorkerModalBottomSheet";
import {userEmployeeCanWriteEmployeeByRoleCascade} from "../../../service/PrivilegesService";
import Icons8NoUser from "../../../icons/Icons8NoUser";
import Icons8AddUser from "../../../icons/Icons8AddUser";
import {processMutationError, processQueryError} from "../../../service/ErrorService";
import DeleteIcon from "../../../icons/DeleteIcon";
import {getBackRoute} from "../../../utils/Utils";
import IEventSystemListener from "../../../model/EventSystemListener";
import {listenToEventSystem, unListenToEventSystem} from "../../../service/EventSystemService";
import IEventSystemNotification from "../../../model/firestore/EventSystemNotification";
import {
    kActionDelete,
    kActionUpdate,
    kActionView,
    kPermissionsWorkers,
    kTopicCompanies,
    kTopicCompanyEmployees
} from "../../../core/constants";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import PermissionsSection from "../../components/permissions/PermissionsSection";
import {updatePermissionsMapJSON, updatePermissionsMapJSONForOption} from "../../../service/PermissionsService";
import {IUpdatePermissionsParams} from "../../components/permissions/EditPermissionsModal";
import PermissionValid, {hasPermission, hasSomePermissions} from "../../components/permissions/PermissionValid";
import BannerTextAndButton from "../../components/banners/BannerTextAndButton";
import NoteItemsSection from "../../components/noteItems/NoteItemsSection";
import AppChip from "../../components/chips/AppChip";
import {getLoginProviderIcon} from "../../../service/LoginProviderService";
import InvitationQRCodeModal from "../../components/modals/InvitationQRCodeModal";
import {FlavorByEnvironment} from "../../../flavor-config";
import {invitationRoute} from "../invitation/InvitationScreen";

export const kWorkerDetailRoute = '/workers/:employeeId';

/**
 * Create navigation route for this screen.
 */
export function workerDetailRoute(employeeId: string | number): string {
    return kWorkerDetailRoute
        .replace(':employeeId', `${employeeId}`);
}

export const useStyles = makeStyles()((theme: Theme) => ({
    pulsingButton: {
        background: kAppColors.primary.main,
        '.MuiButtonBase-root svg': {
            color: kAppColors.text.white,
        },
        ':hover': {
            backgroundColor: kAppColors.hoverColor.main,
        }
    },
    underListItemChipContainer: {
        paddingLeft: 70,
        display: "flex",
        justifyContent: "start",
    },
    tooltipContent: {
        display: "block",
        textAlign: "center",
        whiteSpace: "break-spaces",
        justifyContent: "center",
    }
}));

export default function WorkerDetailScreen() {
    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe, restApiPost, restApiDelete} = restApiClientContext;

    const appContext = useContext(AppContext);
    const {setTitle, authUser} = appContext;

    const appDataContext = useContext(AppDataContext);
    const {company, appModals, setStorage} = appDataContext;

    const {employeeId} = useParams();

    const [inviteCode, setInviteCode] = useState<string>();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<EmployeeDetailResponse | NullOrUndefined>();
    const [refetch, setRefetch] = useState(0);
    const [QRCodeInvitationModal, setQRCodeInvitationModal] = useState<boolean>(false);

    useEffect(() => {
        const subscription = subscribe(
            kTopicCompanyEmployees,
            {
                uri: '/company/employee/detail',
                params: {
                    employeeId: parseInt(employeeId!),
                } as GetEmployeeDetailInput,
                setLoading,
                onData: setData,
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            (notifications: IEventSystemNotification[]) => {
                const update = notifications.some(notification => {
                    if (notification.action !== kActionUpdate) {
                        return false;
                    }

                    return notification.data.id === parseInt(employeeId!);
                });

                return update;
            },
        );

        return () => {
            subscription.cancel();
        };
    }, [employeeId, refetch]);

    useEffect(() => {
        if (data?.customPhotoFile) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...((data?.customPhotoFile ? [data?.customPhotoFile] : undefined) || []),
                    ],
                };
            });
        }
    }, [data]);

    const [mutatePasscode, {
        loading: passcodeLoading,
    }] = useResettableMutation<CreateOrExistingCompanyEmployeePasscodeMutation, CreateOrExistingCompanyEmployeePasscodeMutationVariables>(CreateOrExistingCompanyEmployeePasscodeDocument);

    const [mutateLayoff, {
        loading: layoffLoading,
    }] = useResettableMutation<LayoffCompanyEmployeeMutation, LayoffCompanyEmployeeMutationVariables>(LayoffCompanyEmployeeDocument);

    const [mutateReActivate, {
        loading: reActivateLoading,
    }] = useResettableMutation<ReActivateCompanyEmployeeMutation, ReActivateCompanyEmployeeMutationVariables>(ReActivateCompanyEmployeeDocument);

    const [mutateDelete, {
        loading: deleteLoading,
    }] = useMutation<DeleteCompanyEmployeeMutation, DeleteCompanyEmployeeMutationVariables>(DeleteCompanyEmployeeDocument);

    const [mutateNotification, {
        loading: notificationLoading,
    }] = useMutation<SendNotificationToUsersMutation, SendNotificationToUsersMutationVariables>(SendNotificationToUsersDocument);

    useEffect(() => {
        setTitle(tt('workerDetail.screen.title'))
    }, []);

    /**
     * Generate and show new invite passcode.
     */
    const Passcode = async () => {
        try {
            const variables: CreateOrExistingCompanyEmployeePasscodeMutationVariables = {
                input: {
                    employeeId: parseInt(employeeId!),
                }
            };

            const result = await mutatePasscode({variables});

            if (!result.errors) {
                setInviteCode(result.data!.createOrExistingCompanyEmployeePasscode);
            } else {
                ErrorToast(tt('common.mutation.error'));
            }
        } catch (e) {
            console.error(e);

            ErrorToast(tt('common.mutation.error'));
        }
    };

    /**
     * Show confirm modal and if confirmed mutate delete to BE.
     */
    const DeactivateEmployee = () => {
        SetConfirmModal(
            appDataContext,
            {
                open: true,
                title: tt('deactivateWorker.modal.title'),
                subtitle: tt('deactivateWorker.modal.subtitle'),
                confirmationButtonText: tt('deactivateWorker.modal.button.deactivate'),
                onConfirm: async () => {
                    try {
                        const variables: LayoffCompanyEmployeeMutationVariables = {
                            input: {
                                employeeId: parseInt(employeeId!),
                            },
                        };

                        const result = await mutateLayoff({variables});

                        if (!result.errors) {
                            /*if (result.data!.layoffCompanyEmployee.userId === authUser?.signInResponse?.data?.userId) {
                                navigate(kDashboardRoute);

                                AuthUserUpdateCompanyIds(appContext, appDataContext, {remove: result.data!.layoffCompanyEmployee.companyId});
                            }*/

                            SuccessToast(tt('deactivateWorker.modal.success'));

                            HideConfirmModal(appDataContext);
                        } else {
                            ErrorToast(tt('common.mutation.error'));
                        }
                    } catch (e) {
                        console.error(e);

                        ErrorToast(tt('common.mutation.error'));
                    }
                }
            }
        );
    };

    /**
     * Show confirm modal and if confirmed mutate reactivate to BE.
     */
    const ReactivateEmployee = () => {
        SetConfirmModal(
            appDataContext,
            {
                open: true,
                title: tt('reactivateWorker.Modal.title'),
                subtitle: tt('reactivateWorker.Modal.text'),
                confirmationButtonText: tt('reactivateWorker.Modal.button.reactivate'),
                confirmButtonColor: kAppColors.green.main,
                cancelButtonText: tt('common.close'),
                onConfirm: async () => {
                    try {
                        const variables: ReActivateCompanyEmployeeMutationVariables = {
                            input: {
                                employeeId: parseInt(employeeId!),
                            },
                        };

                        const result = await mutateReActivate({variables});

                        if (!result.errors) {
                            SuccessToast(tt('reactivateWorker.Modal.success'));

                            HideConfirmModal(appDataContext);

                            const userId = data?.employee.user?.id;

                            if (userId) {
                                try {
                                    const dataKeys = ['action', 'companyId', 'companyName'];
                                    const dataValues = ['re_activate_company_employee', company!.id!.toString(), company!.name];

                                    const variables: SendNotificationToUsersMutationVariables = {
                                        input: {
                                            userIds: [userId],
                                            title: tt('common.fcm.reActivateCompanyEmployee.title')
                                                .replace('{companyName}', company!.name),
                                            body: tt('common.fcm.reActivateCompanyEmployee.body')
                                                .replace('{companyName}', company!.name),
                                            dataKeys,
                                            dataValues,
                                        },
                                    };

                                    await mutateNotification({variables});
                                } catch (e) {
                                    processMutationError(e);
                                }
                            }
                        } else {
                            ErrorToast(tt('common.mutation.error'));
                        }
                    } catch (e) {
                        processMutationError(e);
                    }
                }
            }
        );
    };

    /**
     * Show confirm modal and if confirmed mutate delete to BE.
     */
    const DeleteEmployee = () => {
        SetConfirmModal(appDataContext, {
            open: true,
            title: tt('worker.detail.delete.confirm.title'),
            subtitle: tt('worker.detail.delete.confirm.subtitle'),
            confirmationButtonText: tt('common.delete'),
            cancelButtonText: tt('common.close'),
            onConfirm: async () => {
                try {
                    const variables: LayoffCompanyEmployeeMutationVariables = {
                        input: {
                            employeeId: parseInt(employeeId!),
                        },
                    };

                    const result = await mutateDelete({variables});

                    if (!result.errors) {
                        /*if (result.data!.deleteCompanyEmployee.userId === authUser?.signInResponse?.data?.userId) {
                            navigate(kDashboardRoute);

                            AuthUserUpdateCompanyIds(appContext, appDataContext, {remove: result.data!.deleteCompanyEmployee.companyId});
                        }*/

                        SuccessToast(tt('worker.detail.delete.success'));

                        HideConfirmModal(appDataContext);

                    } else {
                        ErrorToast(tt('common.mutation.error'));
                    }
                } catch (e) {
                    processMutationError(e);
                }
            },
            children: <></>,
        });
    };

    useEffect(() => {
        SetConfirmModal(appDataContext, {
            loading: layoffLoading,
        });
    }, [layoffLoading]);

    /**
     * Update permission values to BE.
     */
    const updatePermissions = (params: IUpdatePermissionsParams) => {
        const {permission, option, applyForAllOfRole, otherPermissions} = params;

        try {
            SetEditPermissionsModal(
                appDataContext,
                {
                    ...appModals?.editPermissions,
                    updateLoading: true,
                },
            );

            let permissionsMapJSON: string = option !== null ? updatePermissionsMapJSONForOption(permission, option, data!.permissionsMapJSON) : data!.permissionsMapJSON;

            if (otherPermissions) {
                for (const [key, value] of Object.entries(otherPermissions)) {
                    permissionsMapJSON = updatePermissionsMapJSON(key, value, permissionsMapJSON);
                }
            }

            restApiPost({
                uri: '/company/employee/save-permissions',
                params: {
                    employeeId: parseInt(employeeId!),
                    permissionsMapJSON,
                } as SavePermissionsForEmployeeInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: (value) => SetEditPermissionsModal(
                    appDataContext,
                    {
                        ...appModals?.editPermissions,
                        updateLoading: value,
                    },
                ),
                onData: (data) => {
                    if (data) {
                        SuccessToast(tt('employeePermissions.screen.update.success'));

                        HideEditPermissionsModal(appDataContext);
                    }
                },
                onError: (error: any) => processMutationError(error),
            });
        } catch (e) {
            processMutationError(e);

            SetEditPermissionsModal(
                appDataContext,
                {
                    ...appModals?.editPermissions,
                    updateLoading: false,
                },
            );
        }
    };

    /**
     * Delete Employee permissions to BE which will reset them to use by role.
     */
    const deletePermissions = () => {
        SetConfirmModalLoading(appDataContext, true);

        restApiDelete({
            uri: '/company/employee/permissions',
            params: {
                employeeId: parseInt(employeeId!),
            } as DeleteEmployeePermissionsInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading: (loading: boolean) => SetConfirmModalLoading(appDataContext, loading),
            onData: (data) => {
                if (data) {
                    SuccessToast(tt('employeePermissions.screen.delete.success'));

                    HideConfirmModal(appDataContext);
                }
            },
            onError: (error: any) => processMutationError(error),
        });
    };

    const canInvite = data?.employee.employeeInvitation && !data.employee.employeeInvitation.consumed;

    function bodyJSX(isMobile?: boolean) {
        return (
            <Body
                isMe={authUser?.email === data?.employee.user?.email}
                loading={loading}
                data={data}
                passcode={canInvite ? Passcode : undefined}
                passcodeLoading={passcodeLoading}
                isMobile={isMobile}
                deleteEmployee={DeleteEmployee}
                deactivateEmployee={DeactivateEmployee}
                reactivateEmployee={ReactivateEmployee}
                updatePermissions={updatePermissions}
                deletePermissions={deletePermissions}
            />
        );
    }

    const fullName = UserFullName(data?.employee?.name || data?.employee?.user?.name, data?.employee?.surname || data?.employee?.user?.surname);
    const urlWithParams = encodeURI(`${FlavorByEnvironment()!.webUrl}${invitationRoute(Object.keys(EmployeeRole).indexOf(data?.employee.role || EmployeeRole.None), inviteCode!, fullName)}`);

    return (
        <>
            <EventSystemListeners
                refetch={() => setRefetch(refetch + 1)}
            />

            <ResponsiveContainer
                smallPhoneScreen={bodyJSX(true)}
                largePhoneScreen={bodyJSX(true)}
                tabletScreen={bodyJSX()}
                smallDesktopScreen={bodyJSX()}
                largeDesktopScreen={bodyJSX()}
                extraLargeDesktopScreen={bodyJSX()}
            />
            {
                data === undefined ? null : <>
                    <InviteWorkerModalBottomSheet
                        inviteCode={inviteCode}
                        setInviteCode={setInviteCode}
                        workerData={data!.employee}
                        setOpenQRCode={setQRCodeInvitationModal}
                    />
                    <InvitationQRCodeModal
                        open={QRCodeInvitationModal}
                        setOpen={setQRCodeInvitationModal}
                        urlWithParams={urlWithParams}
                    />
                </>
            }
        </>
    );
}

interface IEventSystemListenersProps {
    refetch: VoidFunction;
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {refetch} = props;

    const {employeeId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicCompanies,
            callback: (notifications: IEventSystemNotification[]) => {
                refetch();
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [refetch, employeeId]);

    useEffect(() => {
        const eventSystemListener: IEventSystemListener = {
            topic: kTopicCompanyEmployees,
            callback: (notifications: IEventSystemNotification[]) => {
                const doDelete = notifications.some(notification => {
                    if (notification.action !== kActionDelete) {
                        return false;
                    }

                    return notification.data.id === parseInt(employeeId!);
                });

                if (doDelete) {
                    navigate(getBackRoute(kWorkersRoute));
                }
            },
        };

        listenToEventSystem(eventSystemListener);

        return () => {
            unListenToEventSystem(eventSystemListener);
        };
    }, [employeeId]);

    return null;
}

interface IBodyProps {
    isMe: boolean;
    loading: boolean;
    data?: EmployeeDetailResponse | NullOrUndefined;
    passcode?: VoidFunction;
    passcodeLoading: boolean
    isMobile?: boolean;
    deleteEmployee: VoidFunction;
    deactivateEmployee: VoidFunction;
    reactivateEmployee: VoidFunction;
    updatePermissions: (params: IUpdatePermissionsParams) => void;
    deletePermissions: VoidFunction;
}

function Body(props: IBodyProps) {
    const {
        isMe,
        loading,
        data,
        passcode,
        passcodeLoading,
        isMobile,
        deleteEmployee,
        deactivateEmployee,
        reactivateEmployee,
        updatePermissions,
        deletePermissions,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {companyEmployeeRole, employeePermissionsMap, storage} = appDataContext;

    const {employeeId} = useParams();

    const theData = data?.employee;
    const canEdit = theData && companyEmployeeRole && userEmployeeCanWriteEmployeeByRoleCascade(
        companyEmployeeRole,
        theData.role,
    );
    const isActive = !!theData?.active;

    const theAddress = UserAddressSingle(theData?.address);
    const addressLink = AddressMapsLink(theAddress);

    const {classes, cx} = useStyles();

    const navigate = useNavigate();

    const settingsPopupState = usePopupState({
        variant: 'popover',
        popupId: useId(),
    });

    const inviteJSX = !!passcode ? (
        <PermissionValid
            permission={kPermissionsWorkers}
            requiredPermissions={[kActionUpdate]}
        >
            <BannerTextAndButton
                text={tt('workerDetail.screen.inviteWorkerBanner.text')}
                buttonText={tt('workerDetail.screen.inviteWorkerBanner.button')}
                loading={passcodeLoading}
                onTap={passcode}
            />
        </PermissionValid>
    ) : undefined;


    const permissionsJSX = useMemo(() => {
        const ownPermissionsJSX = data?.permissionsByRole ? null : (
            <Box pt={2}>
                <BannerTextAndButton
                    text={tt('workerDetail.screen.permissions.employeeHasCustomPermissions').replace('$firstname', theData?.name || theData?.user?.name || '').replace('$role', UserRoleTitle(theData?.role))}
                    buttonText={tt('workerDetail.screen.permissions.reset')}
                    onTap={hasPermission(kPermissionsWorkers, [kActionUpdate], employeePermissionsMap) ? () => {
                        SetConfirmModal(appDataContext, {
                            open: true,
                            title: tt('workerDetail.screen.permissions.reset.confirm.title'),
                            subtitle: tt('workerDetail.screen.permissions.reset.confirm.subtitle'),
                            confirmationButtonText: tt('workerDetail.screen.resetPermissionsConfirmModal.button.confirm'),
                            cancelButtonText: tt('common.close'),
                            children: <></>,
                            onConfirm: () => {
                                deletePermissions();
                            },
                        });
                    } : undefined}
                />
            </Box>
        );

        return data ? (
            <>
                {ownPermissionsJSX}

                <PermissionsSection
                    role={theData!.role}
                    employeePermissionsMap={JSON.parse(data.permissionsMapJSON)}
                    updatePermissions={hasPermission(kPermissionsWorkers, [kActionUpdate], employeePermissionsMap) ? updatePermissions : undefined}
                />
            </>

        ) : <></>;
    }, [data]);


    const tabsData = [
        {
            label: tt('common.contact'),
            content: <ContactSection
                address={theAddress}
                addressLink={addressLink}
                email={theData?.contactEmail}
                phone={theData?.phoneNumber}
            />
        },
    ];

    if (canEdit && hasPermission(kPermissionsWorkers, [kActionView], employeePermissionsMap)) {
        tabsData.push({
            label: tt('workerDetail.screen.tab.notes'),
            content: <NoteItemsSection
                permission={kPermissionsWorkers}
                employeeId={parseInt(employeeId!)}
            />,
        });

        if (canEdit && hasPermission(kPermissionsWorkers, [kActionView], employeePermissionsMap)) {
            tabsData.push({
                label: tt('common.permissions'),
                content: permissionsJSX,
            });
        }
    }

    const tabsJSX = (
        <AppTabsComponent
            data={tabsData}
        />
    );

    const deactivatedBanner = !isActive ? (
        <BannerTextAndButton
            text={tt('workerDetail.screen.deactivatedBanner.text')}
            buttonText={tt('workerDetail.screen.deactivatedBanner.button.makeActive')}
            onTap={hasPermission(kPermissionsWorkers, [kActionUpdate], employeePermissionsMap) ? () => reactivateEmployee() : undefined}
        />
    ) : undefined;

    const contentJSX = loading && !data ? <DetailScreenShimmer/> : (
        <>
            {deactivatedBanner}
            {inviteJSX}
            <AppListItem
                title={UserFullName(theData?.name || theData?.user?.name, theData?.surname || theData?.user?.surname) || tt('common.notFilledIn')}
                description={UserRoleTitle(theData?.role)}
                profileImage={
                    UserPhotoUrl(theData?.user, data?.customPhotoFile ? [data?.customPhotoFile] : undefined, storage.publicUrlsForFiles)
                }
                actionWidget={<></>}
                belowListItemWidget={
                    isMe || !theData?.user?.loginProviders.length ? undefined :
                        <Box className={classes.underListItemChipContainer}>
                            <Tooltip
                                aria-multiline={true}
                                title={<span className={classes.tooltipContent}>
                                    {`${tt('workerDetail.screen.logsInUsing')}\n${
                                        theData?.user?.loginProviders.map((item, index) => {
                                            return `${item}${index < theData!.user!.loginProviders.length - 1 ? ' · ' : ''}`;
                                        }).join('')
                                    }`}
                                </span>}
                            >
                                <Box>
                                    <AppChip
                                        title={''}
                                        chipstyle={"outlined"}
                                        icon={
                                            <>
                                                {
                                                    theData?.user?.loginProviders.map((item, index) => {
                                                        return <Box
                                                            sx={{pl: index < (theData!.user!.loginProviders.length - 1) ? 0.4 : 0}}
                                                            key={item}
                                                        >{getLoginProviderIcon(item)}</Box>;
                                                    })
                                                }
                                                {theData?.user?.email ?
                                                    <span>&nbsp;&nbsp;{theData?.user?.email}</span> : ''}
                                            </>
                                        }
                                    />
                                </Box>
                            </Tooltip>
                        </Box>
                }
            />

            <Box sx={{mb: 2}}/>
            <Divider sx={{mb: 0.5}}/>

            <UserStatusRow
                hourRate={theData?.hourRate}
                hourRateShowZero={true}
                contractType={isMe ? undefined : (theData?.contractType ? ContractTypeString(theData?.contractType) : undefined)}
                distanceRate={theData?.distanceRate}
                distanceRateShowZero={true}
            />

            {tabsJSX}
        </>
    );

    const actionsMenuJSX = theData?.role !== EmployeeRole.Admin && canEdit
    && hasSomePermissions([
        {permission: kPermissionsWorkers, requiredPermissions: [kActionUpdate]},
        {permission: kPermissionsWorkers, requiredPermissions: [kActionDelete]},
    ], employeePermissionsMap) ? (
        <AppIconButton key={'workerDetailBtn2'}
                       {...bindTrigger(settingsPopupState)}
        >
            <MoreFilledIcon/>
        </AppIconButton>
    ) : null;

    return (
        <ScreenContent
            appBar={!isMobile}
            noContentPadding={isMobile}
            navigationDrawer={!isMobile}
            bottomBar={isMobile}
            centerHorizontally={true}>
            <AppPaper
                sx={{maxWidth: isMobile ? undefined : kContentWidthMedium}}
            >
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('worker.detail.content.title')}
                    backRoute={getBackRoute(kWorkersRoute)}>
                    {canEdit ? (
                        <PermissionValid
                            permission={kPermissionsWorkers}
                            requiredPermissions={[kActionUpdate]}
                        >
                            <AppIconButton
                                key={'workerDetailBtn1'}
                                onClick={() => {
                                    navigate(kWorkerEditRoute.replace(':id', employeeId!));
                                }}>
                                <PencilIcon/>
                            </AppIconButton>
                        </PermissionValid>
                    ) : undefined}

                    {actionsMenuJSX}
                </PaperAppbar>

                <Menu {...bindMenu(settingsPopupState)}>
                    <PermissionValid
                        permission={kPermissionsWorkers}
                        requiredPermissions={[kActionUpdate]}
                    >
                        {isActive ? (
                            <MenuItem key={'workerDetailDeactivateKey'} onClick={() => {
                                settingsPopupState.close();

                                deactivateEmployee();
                            }}>
                                <Box sx={{color: kAppColors.red.confirmButton}}><Icons8NoUser/></Box>
                                {tt('workerDetail.screen.menuItem.deactivate')}
                            </MenuItem>
                        ) : (
                            <MenuItem key={'workerDetailReactivateKey'} onClick={() => {
                                settingsPopupState.close();

                                reactivateEmployee();
                            }}>
                                <Box sx={{color: kAppColors.green.main}}><Icons8AddUser/></Box>
                                {tt('workerDetail.screen.menuItem.reactivate')}
                            </MenuItem>
                        )}
                    </PermissionValid>

                    {!isActive ? (
                        <PermissionValid
                            permission={kPermissionsWorkers}
                            requiredPermissions={[kActionDelete]}
                        >
                            <MenuItem key={'workerDetailDeleteKey'} onClick={() => {
                                settingsPopupState.close();
                                deleteEmployee();
                            }}>
                                <Box sx={{color: kAppColors.red.confirmButton}}><DeleteIcon/></Box>
                                {tt('common.delete')}
                            </MenuItem>
                        </PermissionValid>
                    ) : undefined}
                </Menu>

                {contentJSX}
            </AppPaper>
        </ScreenContent>
    );
}
