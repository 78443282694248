import {useContext} from "react";
import {tt} from "../../../core/Localization";
import {kAppColors, kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import Icons8Done from "../../../icons/Icons8Done";
import {Box, Divider, Theme, Typography} from "@mui/material";
import AppListItemV2 from "../listItems/ApplistItemV2";
import AppModal from "./AppModal";
import AppChip from "../chips/AppChip";
import {makeStyles} from "tss-react/mui";
import Icons8SandClock from "../../../icons/Icons8SandClock";
import {HideTrialInfoModal} from "./AppModals";
import {AppDataContext} from "../../../AppData";
import {hasPermission} from "../permissions/PermissionValid";
import {kActionCreate, kPermissionsCompanySubscription} from "../../../core/constants";
import {GetSubscriptionDaysLeft, openSubscriptionBuyForm, openTrialExtensionRequestForm} from "../../../service/SubscriptionService";

export const useStyles = makeStyles()((theme: Theme) => ({
    modalPaper: {
        maxWidth: kContentWidthNarrow,
    },
    chip: {
        flexGrow: 1,
    },
    confirmChip: {
        flexGrow: 1,
    },
    closeButtonContainer: {
        flexGrow: 1,
        display: "flex",
        '.MuiChip-root': {
            margin: "auto",
            minWidth: 190,
        }
    },
    centeredHeader: {
        paddingTop: 20,
        paddingLeft: 16,
        paddingRight: 16,
    },
    centeredHeaderTitle:
        {
            fontSize: 20,
            fontWeight: 500,
            textAlign: "center",
            marginBottom: 16,
            "@media (max-width: 767px)": {
                fontSize: 16,
            }
        },
    centeredHeaderSubtitle: {
        color: kAppColors.text.secondary(theme.palette.mode === "dark"),
        fontSize: 14,
        fontWeight: 500,
        textAlign: "center",
        marginBottom: 16,
    },
    topDecorationIcon: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: 20,
        span: {
            width: 40,
            height: 40,
        },
        svg: {
            color: kAppColors.trial.main,
            width: 40,
            height: 40,
        }
    },
    outlinedChip: {
        transition: 'all 0.3s linear',
        backgroundColor: `transparent !important`,
        border: `1px solid ${kAppColors.trial.main}`,
        "&:hover": {
            backgroundColor: `transparent !important`,
            border: `1px solid #716402`,
        },
        'span': {color: kAppColors.trial.main},
        maxWidth: 'calc(50% - 8px)',
    },
    filledChip: {
        width: '100%',
        backgroundColor: `${kAppColors.trial.main} !important`,
        transition: 'all 0.3s linear',
        "&:hover": {
            backgroundColor: `${kAppColors.trial.hover} !important`,
        },
    }
}));

export interface ITrialInfoModalProps {
    open: boolean;
}

export default function TrialInfoModal(props: ITrialInfoModalProps) {
    const {
        open,
    } = props;

    const appDataContext = useContext(AppDataContext);
    const {employeePermissionsMap, companySubscriptionStatus, company, companyData} = appDataContext;

    const {classes, cx} = useStyles();

    const days = GetSubscriptionDaysLeft(companySubscriptionStatus?.subscription);

    const permission = hasPermission(kPermissionsCompanySubscription, [kActionCreate], employeePermissionsMap);

    const actionsJSX = (
        <>
            {permission ? (
                <>
                    <AppChip
                        className={cx(classes.confirmChip, classes.outlinedChip)}
                        onClick={() => {
                            HideTrialInfoModal(appDataContext);

                            openTrialExtensionRequestForm(
                                company!,
                                companyData.employee!,
                            );
                        }}
                        chipstyle={"primary"}
                        label={tt('trialInfoModal.button.extend')}
                    />

                    <Box key={'confirmPopupSpaceKey'} pr={2}/>
                </>
            ) : null}

            <AppChip
                className={classes.chip}
                onClick={(event) => {
                    HideTrialInfoModal(appDataContext);
                }}
                chipstyle={"secondary"}
                label={tt('common.cancel')}
            />
        </>
    );

    return (
        <AppModal
            blurBackdrop={true}
            modalAboveModals={true}
            noContentPadding={true}
            paperClassName={classes.modalPaper}
            children={<>
                <Box className={classes.centeredHeader}>
                    <Box className={classes.topDecorationIcon}>
                        <Icons8SandClock/>
                    </Box>
                    <Typography
                        className={classes.centeredHeaderTitle}>{tt('trialInfoModal.title').replace('$days', days)}</Typography>
                </Box>

                <AppListItemV2
                    variant={"smaller-title"}
                    title={tt('trialInfoModal.item.allFeatures')}
                    customAvatar={<Box sx={{color: kAppColors.trial.main}}><Icons8Done/></Box>}
                    actionWidget={<></>}
                />
                <Divider/>
                <AppListItemV2
                    variant={"smaller-title"}
                    title={tt('trialInfoModal.item.unlimitedSupport')}
                    customAvatar={<Box sx={{color: kAppColors.trial.main}}><Icons8Done/></Box>}
                    actionWidget={<></>}
                />
                <Divider/>
                <AppListItemV2
                    variant={"smaller-title"}
                    title={tt('trialInfoModal.item.dailyBackups')}
                    customAvatar={<Box sx={{color: kAppColors.trial.main}}><Icons8Done/></Box>}
                    actionWidget={<></>}
                />
                <Box pb={2}/>
                {permission ? (
                    <Box pl={2} pr={2}>
                        <AppChip
                            className={cx(classes.confirmChip, classes.filledChip)}
                            onClick={() => {
                                HideTrialInfoModal(appDataContext);

                                openSubscriptionBuyForm(
                                    company!,
                                    companyData.employee!,
                                );
                            }}
                            chipstyle={"primary"}
                            label={tt('trialInfoModal.confirm.button')}
                        />

                        <Box key={'confirmPopupSpaceKey'} pr={2}/>
                    </Box>
                ) : null}
                <Box pb={2}/>
            </>}
            open={open}
            actions={actionsJSX}
        />
    );
}
