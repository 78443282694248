import {makeStyles} from "tss-react/mui";
import {Box, CircularProgress, Theme, Tooltip} from "@mui/material";
import {kAppColors} from "../../../styles/AppThemeProcessor";
import {DateTime} from "luxon";
import TimotyLogo from "../../../icons/TimotyLogo";
import {GetSubscriptionLeftAsPercentage} from "../../../service/SubscriptionService";
import {useContext} from "react";
import {AppDataContext} from "../../../AppData";
import {SetTrialInfoModal} from "../modals/AppModals";
import {tt} from "../../../core/Localization";

const useStyles = makeStyles()((theme: Theme) => ({
    logo: {
        width: 42,
        height: 42,
        color: kAppColors.text.primary(theme.palette.mode === 'dark'),
    },
    logoContainer: {
        position: "relative",
        '.MuiCircularProgress-root': {
            position: "absolute",
            top: -5,
            bottom: -5,
            left: -5,
            right: -5,
        }
    },
    hover: {
        cursor: "pointer",
    },
}));


export interface ILogoWithProgressIndicatorProps {}

export default function LogoWithSubscriptionIndicator(props: ILogoWithProgressIndicatorProps) {
    const appDataContext = useContext(AppDataContext);
    const {companyData, companySubscriptionStatus} = appDataContext;

    const {classes, cx} = useStyles();

    let trialPercentage = 0;

    if (companyData.subscriptionIsValid && companyData.subscriptionIsTrial && companySubscriptionStatus?.subscription) {
        trialPercentage = GetSubscriptionLeftAsPercentage(
            DateTime.fromMillis(companySubscriptionStatus.subscription.validFrom),
            DateTime.fromMillis(companySubscriptionStatus.subscription.validTo),
        );
    }

    return (
        <Box
            className={cx(
                classes.logoContainer,
                trialPercentage ? classes.hover : undefined,
            )}
            onClick={trialPercentage ? () => {
                SetTrialInfoModal(appDataContext, {
                    open: true,
                });
            } : undefined}
        >
            <TimotyLogo className={classes.logo}/>

            {trialPercentage ?
                <Tooltip title={tt('appbarLogo.trial.tooltip')}>
                    <CircularProgress
                    sx={{color: kAppColors.trial.main}}
                    thickness={2}
                    variant={"determinate"}
                    value={trialPercentage} size={52}/>
                </Tooltip>
                : null}
        </Box>
    );
};
