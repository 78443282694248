import {Box, Theme, Typography} from "@mui/material";
import AppChip from "../chips/AppChip";
import React, {ReactNode} from "react";
import {makeStyles} from "tss-react/mui";
import {kAppColors, kContentWidthNarrow} from "../../../styles/AppThemeProcessor";


export const useStyles = makeStyles()((theme: Theme) => ({
    banner: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 16,
        marginRight: "auto",
        marginLeft: "auto",
        marginBottom: 8,
        borderRadius: 10,
        maxWidth: kContentWidthNarrow,
        backgroundColor: kAppColors.yellow.main,
        "@media (max-width: 767px)": {
            marginRight: "16",
            marginLeft: "16",
        },
        'p': {
            color: "black",
            marginBottom: 20,
            textAlign: "center",
        }
    },
    headline: {
        fontWeight: 500,
        color: "black",
        fontSize: 20,
        marginBottom: 20,
        textAlign: "center",
    },
    headlineTrial: {
        color: 'white',
    },
    isTrial: {
        background: kAppColors.trial.main,
        'p': {
            color: "white",
        }
    },
    topDecorationContainer: {
        color: "black",
        marginBottom: 16,
        paddingTop: 12,
        svg: {
            width: 40,
            height: 40,
        },
        span: {
            width: 40,
            height: 40,
        }
    },
    topDecorationContainerIsTrial: {
        color: "white",
    },
    buttonsContainer: {
        width: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 16,
    }
}));


export interface IBannerTextAndButtonV2Props {
    title: string;
    text?: string;
    onTap?: Function;
    buttonIcon?: React.ReactElement,
    topDecoration?: React.ReactElement,
    buttonText: string;
    loading?: boolean;
    onTap2?: Function;
    buttonIcon2?: React.ReactElement,
    topDecoration2?: React.ReactElement,
    buttonText2?: string;
    variant?: 'trial' | 'default';
}

export default function BannerTextAndButtonV2(props: IBannerTextAndButtonV2Props) {
    const {
        buttonText,
        title,
        text,
        buttonIcon,
        onTap,
        loading,
        variant = 'trial',
        topDecoration,
        buttonText2,
        buttonIcon2,
        topDecoration2,
        onTap2,
    } = props;

    const {classes, cx} = useStyles();

    return (
        <Box className={cx(classes.banner, variant === 'trial' ? classes.isTrial : null)}>
            {topDecoration ? <Box
                className={cx(classes.topDecorationContainer, variant === 'trial' ? classes.topDecorationContainerIsTrial : null)
                }>{topDecoration}</Box> : null}

            <Typography
                className={cx(classes.headline, variant === 'trial' ? classes.headlineTrial : null)}>{title}</Typography>
            {text ? <Typography>{text}</Typography>
                : null}

            <Box className={classes.buttonsContainer}>
                {onTap ? (
                    <AppChip
                        icon={buttonIcon}
                        isLoading={loading}
                        onClick={() => onTap()}
                        chipstyle={"tertiary"}
                        label={buttonText}
                    />
                ) : null}

                {onTap2 ? (
                    <AppChip
                        icon={buttonIcon2}
                        onClick={() => onTap2()}
                        chipstyle={"tertiary"}
                        label={buttonText2}
                    />
                ) : null}
            </Box>

        </Box>
    );
}
