import {makeStyles} from "tss-react/mui";
import {Box, InputAdornment, Theme} from "@mui/material";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../../../AppData";
import {tt} from "../../../../../core/Localization";
import AppListItem from "../../../listItems/AppListItem";
import {CheckCircle} from "@mui/icons-material";
import ModalBottomSheet from "../../../ModalBottomSheet";
import BottomSheetModalAppbar from "../../BottomSheetModalAppbar";
import AppButton from "../../../buttons/AppButton";
import {EmployeeJoinedUserResponsePage, GetEmployeesJoinedUsersInput} from "../../../../../generated/graphql/graphql";
import ListShimmer from "../../../shimmers/ListShimmer";
import {UserFullName, UserPhotoUrl, UserRoleTitle} from "../../../../../service/UserService";
import EmptyListText from "../../../textComponents/EmptyListText";
import {SortEmployeesByName} from "../../../../../service/EmployeeService";
import FormBuilder, {IInputsData, InputType} from "../../../form/FormBuilder";
import SearchIcon from "../../../../../icons/SearchIcon";
import WorkersIcon from "../../../../../icons/WorkersIcon";
import {processQueryError} from "../../../../../service/ErrorService";
import {RestApiClientContext} from "../../../../../core/RestApiProvider";

export const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        maxHeight: '80vh',
        height: '100vh',
        overflow: "auto",
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 16,
    },
}));

export interface IChoseWorkerModalBottomSheetProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onSave: (worker: number | null) => void;
    employeeId: number | null;
    modalAboveModals?: boolean;
}

export default function ChooseWorkerModalBottomSheet(props: IChoseWorkerModalBottomSheetProps) {
    const {
        open,
        setOpen,
        onSave,
        employeeId,
        modalAboveModals,
    } = props;

    const {classes} = useStyles();

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const {companyId, storage, setStorage} = appDataContext;

    const [selected, setSelected] = useState<number | null>(employeeId);

    const [inputs, setInputs] = useState<IInputsData>({
        search: {
            type: InputType.Text,
            label: '',
            value: '',
            placeholder: tt('workers.screen.searchForWorker'),
            hideLabel: true,
            inputVariant: 'standard',
            extraStyle: 'thin',
            required: true,
            isClearable: true,
            innerPrefixJSX: <InputAdornment position={"start"}>
                <SearchIcon/>
            </InputAdornment>,
        },
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<EmployeeJoinedUserResponsePage | NullOrUndefined>();
    useEffect(() => {
        if (open) {
            restApiGet({
                uri: '/company/employee/search-joined-users',
                params: {
                    companyId: companyId!,
                    active: true,
                } as GetEmployeesJoinedUsersInput,
                setLoading,
                onData: setData,
                onError: (error) => {
                    processQueryError(appDataContext, error);
                },
            });
        }
    }, [open, companyId]);

    useEffect(() => {
        if (data && open) {
            setStorage((prev) => {
                return {
                    filesToProcess: [
                        ...prev.filesToProcess,
                        ...(data.files || []),
                    ],
                };
            });
        }
    }, [data, open]);

    const content = loading && !data ?
        (
            <ListShimmer items={3} showIcon={false}/>
        ) : data && data.content.length > 0 ?
            SortEmployeesByName(
                data!.content
                    .filter(employee => {
                        const fullName = UserFullName(employee?.name || employee?.user?.name, employee?.surname || employee?.user?.surname);

                        return fullName.toLowerCase().includes(inputs.search.value.toLowerCase());
                    })
            )
                .map(employee => {
                    return (
                        <AppListItem
                            profileImage={
                                UserPhotoUrl(employee.user, data?.files, storage.publicUrlsForFiles)
                            }
                            key={employee.id}
                            variant={"smaller-title"}
                            onClick={() => setSelected(employee.id)}
                            title={UserFullName(employee?.name || employee?.user?.name, employee?.surname || employee?.user?.surname)}
                            description={UserRoleTitle(employee.role)}
                            actionWidget={
                                selected === employee.id ? <CheckCircle color={"primary"}/> : <></>
                            }
                        />
                    );
                })
            : (
                <EmptyListText text={tt('workers.screen.emptyListMessage')}/>
            );

    return (
        <ModalBottomSheet
            tallOnMobile={true}
            blurBackdrop={true}
            open={open}
            setOpen={setOpen}
            hideHeader={true}
            modalAboveModals={modalAboveModals}
            children={
                <Box className={classes.container}>
                    <BottomSheetModalAppbar
                        onClose={() => setOpen(false)}
                        title={tt('common.worker')}
                        bottomContent={
                            <FormBuilder inputs={inputs} setInputs={setInputs}/>
                        }
                    ></BottomSheetModalAppbar>

                    <AppListItem
                        variant={"smaller-title"}
                        onClick={() => setSelected(null)}
                        title={tt('common.allWorkers')}
                        customAvatarInCircle={<WorkersIcon/>}
                        actionWidget={
                            !selected ? <CheckCircle color={"primary"}/> : <></>
                        }
                    />

                    {content}

                    <Box sx={{pb: 10}}/>

                    <Box className={classes.buttonContainer}>
                        <AppButton
                            variant={"contained"}
                            disabled={loading}
                            fullWidth={true}
                            onClick={() => {
                                onSave(selected);

                                setOpen(false);
                            }}>
                            {tt('common.save')}
                        </AppButton>

                    </Box>
                </Box>
            }
        />
    );
}
